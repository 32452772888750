:root {
  --pf-brown: #B96E4E;
  /* color: hsl(223, 84%, 63%); */
  --pf-blue: #527EF0;
  --pf-light-blue: #8A9ECE;
  --pf-light-blue-2: hsl(223, 84%, 70%);
  --pf-gray: #727272;
  --pf-dark: #3C3C3C;
  --pf-dark-2: #212529;
  --pf-lavender: #BAA7E1;
  --pf-bkg: #F5F8FA;
  --pf-light-1: #FDFDFD;
  --pf-light-2: #F3F4F4;
  --pf-light-3: #E6E6E6;
  --pf-pink: #FF8787;
}
* {
  box-sizing: border-box;
}
html,
body {
  font-family: Avenir, sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: clip;
  height: 100%;
}
.App {
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 100%;
  min-height: 100vh;
  background-color: var(--pf-bkg);
  font-family: 'DM Sans', sans-serif;
}
.header-margin {
  margin-top: 50px;
}
.spinner-border {
  margin-right: 5px;
}
.fw-semi-bold {
  font-weight: 500;
}
.avatar {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
}
.match-info {
  position: relative;
  left: 0.5em;
  opacity: 0.5;
}
.match-info:hover {
  opacity: 1;
}
::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #81a0f1;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #3C6CE9;
}
