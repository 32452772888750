.loading-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  position: relative;
  margin: auto 0;
  bottom: 4rem;
  --width: 275px;
}
.loading-component img {
  width: var(--width);
}
.loading-component .loading-bar {
  width: calc(var(--width) / 2);
  height: 5px;
  overflow: hidden;
  background-color: hsl(0, 0%, 80%);
}
@-webkit-keyframes slide {
  from {
    margin-left: -80%;
  }
  to {
    margin-left: 120%;
  }
}
@keyframes slide {
  from {
    margin-left: -80%;
  }
  to {
    margin-left: 120%;
  }
}
.loading-component .loading-bar-progress {
  background-color: var(--pf-blue);
  animation: slide 0.9s linear 0s infinite running;
  -webkit-animation: slide 0.9s linear 0s infinite running;
  width: 30%;
  height: 100%;
}
.loading-component .loading-message {
  width: calc(var(--width) * 0.75);
  text-align: center;
}
.loading-component .loading-message .loading-error {
  margin-top: 1rem;
}
.loading-component .loading-message a {
  display: block;
  text-decoration: none;
  color: var(--pf-blue);
}
