.overview-component {
  margin-top: 2rem;
}
.overview-component .loading-container {
  display: inline;
  margin-left: 25px;
  font-size: 36px;
  font-weight: 500;
}
.overview-component .overview-container {
  display: flex;
  margin-right: 600px;
}
.overview-component .overview-container .match-info {
  position: relative;
  left: 0.5em;
  opacity: 0.5;
}
.overview-component .overview-container #overview-nav-tabs {
  /* Nav Bar */
  overflow-x: visible;
  width: 870px;
}
.overview-component .overview-container #overview-nav-tabs #languages-nav-tab {
  margin-top: 2px;
}
.overview-component .overview-container #overview-nav-tabs #skills-nav-tab {
  margin-top: 1px;
}
.overview-component .overview-container #overview-nav-tabs #degrees-nav-tab {
  margin-top: 5px;
}
.overview-component .overview-container #overview-nav-tabs #education-nav-tab {
  margin-top: 5px;
}
.overview-component .overview-container #overview-nav-tabs #industries-nav-tab {
  margin-top: 2px;
}
.overview-component .overview-container #overview-nav-tabs .icon-spacing {
  margin-right: 10px;
}
.overview-component .overview-container #overview-nav-tabs .icon-languages {
  width: 20%;
}
.overview-component .overview-container #overview-nav-tabs .icon-skills {
  width: 30%;
}
.overview-component .overview-container #overview-nav-tabs .icon-degrees {
  width: 20%;
}
.overview-component .overview-container #overview-nav-tabs .icon-education {
  width: 16%;
}
.overview-component .overview-container #overview-nav-tabs .icon-industries {
  width: 25%;
}
.overview-component .overview-container .tab-content {
  margin-top: 10px;
}
.overview-component .overview-container .tab-content .rectangle:hover {
  opacity: 0.66;
  cursor: pointer;
}
.overview-component .overview-container .tab-content .not-enough-data {
  width: 750px;
  font-size: 24px;
}
.overview-component .overview-container .tab-content #viewer {
  margin-top: 25px;
  text-align: center;
}
.overview-component .overview-container .tab-content #viewMore {
  /* View More Button */
  padding: 5px 10px;
  background: #527ef0;
  color: white;
  border-radius: 20px;
  border: transparent;
}
.overview-component .overview-container .card {
  border-radius: 10px;
  box-shadow: 0 4px 4px 0 hsl(0, 0%, 75%);
}
.overview-component .overview-container .card #chart-container {
  position: relative;
  width: 860px;
  height: 525px;
}
.overview-component .overview-container .card-margin {
  margin-left: 90px;
  margin-top: 58px;
}
.overview-component .overview-container .card-margin .description-card {
  width: 21rem;
}
.overview-component .overview-container .card-margin .description-card .loading > * {
  opacity: 0.5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.overview-component .overview-container .card-margin .description-card .card-title {
  font-size: 1.5rem;
  font-weight: bold;
}
.overview-component .overview-container .card-margin .description-card .term-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.overview-component .overview-container .card-margin .description-card .term-info .term-info-description {
  margin-bottom: 0;
}
.overview-component .overview-container .card-margin .description-card .term-info .term-info-separator {
  width: 50%;
  border-bottom: 1px solid var(--pf-light-blue);
  margin: 1.5rem 0;
}
.overview-component .overview-container .card-margin .description-card .term-info .term-info-explanation {
  margin: 0 1.5rem;
}
.overview-component .transparency {
  margin: 4rem 0;
  font-size: 0.8rem;
}
.overview-component .transparency p {
  font-size: 1.1em;
}
