.bitsinbio-background {
  background-color: #41A7AB;
}
.community-landing .bitsinbio-color {
  color: #41A7AB;
}
.community-landing .bitsinbio-color a {
  color: #41A7AB;
}
.chat-response-container .bitsinbio-color {
  color: #26A2A9;
}
.chat-response-container .bitsinbio-color a {
  color: #26A2A9;
}
.discoverv2-component .bitsinbio-color {
  color: white;
}
.discoverv2-component .bitsinbio-color a {
  color: white;
}
.bobatalks-background {
  background-color: #F1DAC5;
}
.bobatalks-color {
  color: #0B2348;
}
.bobatalks-color a {
  color: #0B2348;
}
