.reaction-container {
  display: flex;
  position: relative;
  align-items: flex-end;
  color: #527EF0;
}
.reaction-modal-container {
  box-sizing: border-box;
  position: absolute;
  border: none;
  transform: translateY(-80px);
  background-color: white;
  padding: 0px 5px 0px 5px;
  border-radius: 20px;
  z-index: 1000;
  justify-content: space-between;
  background: #F2F2F2;
  border: 1px solid #DCDCDC;
}
.reaction-modal-container .reaction-modal-content {
  display: flex;
  position: relative;
  background: transparent;
}
.reaction-modal-container .reaction-modal-entry {
  color: black;
}
.add-reaction-modal {
  z-index: 1000;
}
.reaction-button {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-right: 5px;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  background-color: #E6E9F0;
  border: 1px solid #DCDCDC;
  gap: 0.3rem;
}
.reaction-button:not(.story-preview):hover {
  outline: 1px solid #454545;
}
.reaction-button.story-preview {
  display: flex;
  height: 27px;
  padding: 5px;
  align-items: center;
  gap: 5px;
}
.reaction-button-selected {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-right: 5px;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  border: 1px solid #527EF0;
  background-color: #E6E9F0;
  gap: 0.3rem;
}
.reaction-button-selected:not(.story-preview):hover {
  outline: 1px solid #454545;
}
.reaction-button-selected.story-preview {
  display: flex;
  height: 27px;
  padding: 5px;
  align-items: center;
  gap: 5px;
}
.reaction-text {
  cursor: pointer;
  user-select: none;
}
.reaction-icon {
  cursor: pointer;
  margin: 7px;
  transition: all 0.25s;
  transform-origin: 50% 50%;
}
.reaction-icon:hover {
  transform: scale(1.4);
}
