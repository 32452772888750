.results-component {
  margin: 1.5rem auto;
}
.results-component .list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}
.results-component .scroll-to-top {
  --size: 75px;
  position: fixed;
  bottom: calc(var(--size) / 2);
  right: 15%;
  width: var(--size);
  height: var(--size);
  border: 2px solid #6c63ff;
  border-radius: calc(var(--size) / 2);
  background: white;
  box-shadow: 0 0 10px 1px hsl(240, 50%, 75%);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  cursor: pointer;
  transform: translateY(calc(var(--size) + 50%));
  transition: all 0.75s ease;
  z-index: 2;
}
.results-component .show-button {
  opacity: 1;
  transform: translateY(0);
}
.results-component .searching {
  margin-left: 25px;
  font-size: 36px;
  font-weight: 500;
}
.results-component h1 {
  font-size: 2.5rem;
}
.results-component .results-count {
  margin-top: 25px;
  font-size: 18px;
}
.results-component h3 {
  font-size: 24px;
  margin-bottom: 20px;
}
.results-component ul,
.results-component li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.results-component .result-sections .results-section {
  margin-top: 25px;
}
.results-component .result-sections .results-section .match-info {
  position: relative;
  left: 0.5em;
  opacity: 0.5;
}
.results-component .result-sections .results-section .results-list {
  columns: 2;
}
.results-component .result-sections .results-section .results-list .result {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 8px;
  font-size: 20px;
  border-radius: 0.3rem;
  width: 75%;
}
.results-component .result-sections .results-section .results-list .result .result-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}
.results-component .result-sections .results-section .results-list .result:hover {
  background: hsl(0, 0%, 90%);
  cursor: pointer;
}
.results-component .result-sections .results-section .results-list .result:hover .arrow-right {
  opacity: 1;
}
.results-component .result-sections .results-section .results-list .result .percent-match-container {
  background: white;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 10px;
  border: 1px solid hsl(0, 0%, 85%);
  border-radius: 20px;
  white-space: nowrap;
}
.results-component .result-sections .results-section .results-list .result .arrow-right {
  opacity: 0;
  margin-left: auto;
  margin-right: 1rem;
  width: 30px;
  height: 18px;
}
@media screen and (max-width: 1024px) {
  .results-component .result-sections .results-section .results-list .result {
    font-size: 18px;
    width: 100%;
  }
  .results-component .result-sections .results-section .results-list .result .percent-match-container {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .results-component .result-sections .results-section .results-list {
    columns: 1;
  }
  .results-component .result-sections .results-section .results-list .result .arrow-right {
    display: none;
  }
  .results-component .result-sections .results-section .results-list .result .percent-match-container {
    margin-left: auto;
  }
}
