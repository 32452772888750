.stories-link {
  text-decoration: none;
}
.story-component {
  display: flex;
  flex-direction: column;
  max-height: 780px;
  width: 100%;
  padding: 32px 0 32px 32px;
  background-color: #DCE6FF;
  border-radius: 10px;
}
@media screen and (max-width: 768px) {
  .story-component {
    max-height: none;
  }
}
.story-component .scroll-container {
  overflow-y: scroll;
  padding-right: 32px;
  border-radius: 10px;
}
.story-component .scroll-container .users-links {
  margin-top: 8px;
  padding-left: 56px;
}
.story-component .scroll-container .user-link {
  color: #3C6CE9;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0035rem;
  text-decoration: none;
}
.story-component .scroll-container .user-link:hover {
  text-decoration: underline;
}
.story-component .scroll-container .story-header {
  display: flex;
  justify-content: space-between;
}
.story-component .scroll-container .story-header .story-header-main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.story-component .scroll-container .story-header .story-header-main .story-header-img {
  margin-right: 16px;
}
.story-component .scroll-container .story-header .story-header-main .story-header-title {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.story-component .scroll-container .story-header .story-header-main .story-header-title h4 {
  margin-bottom: 0;
}
.story-component .scroll-container .story-header .story-header-main .story-header-title p {
  font-size: 0.8em;
  margin: 0;
}
.story-component .scroll-container .story-header .story-header-main .story-header-title .subtitle-links .scheduleMtgLink {
  text-decoration: none;
}
.story-component .scroll-container .story-header .story-header-side {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
}
.story-component .scroll-container .story-header .story-header-side .switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}
.story-component .scroll-container .story-header .story-header-side .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.story-component .scroll-container .story-header .story-header-side .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}
.story-component .scroll-container .story-header .story-header-side .slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.story-component .scroll-container .story-header .story-header-side input:checked + .slider {
  background-color: #2196F3;
}
.story-component .scroll-container .story-header .story-header-side input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
.story-component .scroll-container .story-header .story-header-side input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}
.story-component .story-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 16px;
}
.story-component .story-body .qa-container {
  margin-bottom: 1px;
  margin-left: 1px;
  padding: 20px 30px;
  border-radius: 8px;
  background-color: #F5F8FA;
  box-shadow: 1px 1px 15px 0px rgba(131, 131, 131, 0.2);
}
.story-component .story-body .qa-container.false {
  cursor: pointer;
}
.story-component .story-body .qa-container .qa-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  line-height: normal;
}
.story-component .story-body .qa-container .qa-question .qa-button {
  height: 20px;
  width: 20px;
  margin-left: 50px;
  flex-shrink: 0;
}
.story-component .story-body .qa-container.open {
  outline: 1px #3C6CE9 solid;
}
.story-component .story-body .qa-container.open .qa-question {
  color: #3C6CE9;
  margin-bottom: 15px;
}
.story-component .story-body .qa-container.open .qa-question .qa-button {
  color: black;
}
.discover-story-component {
  display: flex;
  flex-direction: column;
  width: auto;
  max-height: 450px;
  padding: 20px 40px;
  border: 1px solid #6E6E6E;
  border-radius: 8px;
  background-color: white;
  color: var(--pf-dark-2);
  font-family: arial;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  cursor: pointer;
  overflow: visible;
}
@media screen and (max-width: 768px) {
  .discover-story-component {
    max-height: none;
    padding: 20px;
  }
}
.discover-story-component:hover {
  outline: 2px solid var(--pf-light-3);
  color: var(--pf-dark-2);
}
.discover-story-component .users-links {
  margin: 4px 0;
  padding-left: 64px;
}
.discover-story-component .user-link {
  color: #3C6CE9;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0035rem;
  text-decoration: none;
}
.discover-story-component .user-link:hover {
  text-decoration: underline;
}
.discover-story-component .discover-story-header {
  display: flex;
  text-decoration: none;
  align-items: center;
  color: var(--pf-dark-2);
}
@media screen and (max-width: 768px) {
  .discover-story-component .discover-story-header {
    flex-direction: column;
    align-items: flex-start;
  }
}
.discover-story-component .discover-story-header .avatar-container,
.discover-story-component .discover-story-header .mobile-subheader .avatar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}
.discover-story-component .discover-story-header .avatar-container .avatar,
.discover-story-component .discover-story-header .mobile-subheader .avatar-container .avatar {
  width: 48px;
  height: 48px;
  border: 1px solid #C6C9CE;
}
.discover-story-component .discover-story-header .header .title {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 0;
}
.discover-story-component .discover-story-header .header .subtitle {
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0.056px;
}
.discover-story-component .discover-story-header .header .subtitle-links {
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0.056px;
}
.discover-story-component .discover-story-header .header .subtitle-links .scheduleMtgLink {
  text-decoration: none;
}
.discover-story-component .discover-story-header .date {
  margin-left: auto;
  font-size: 12px;
}
.discover-story-component .mobile-subheader {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  align-items: center;
}
.discover-story-component .responses-container {
  margin-top: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  color: black;
}
.discover-story-component .responses-container .response-question {
  font-size: 18px;
  font-weight: bold;
  text-overflow: ellipsis;
  letter-spacing: 0.03px;
}
.discover-story-component .responses-container .response-question:not(:first-child) {
  margin-top: 1rem;
}
.discover-story-component .responses-container .response-answer {
  font-size: 16px;
  letter-spacing: 0.016px;
  display: inline;
}
.discover-story-component .responses-container .response-answer a:hover {
  color: #0d6efd;
}
.discover-story-component .responses-container .view-more-button {
  display: inline;
  align-items: center;
  color: #3C6CE9;
}
.discover-story-component .discover-story-footer {
  display: flex;
  margin-top: 20px;
  align-items: center;
  padding-bottom: 0;
}
@media screen and (max-width: 768px) {
  .discover-story-component .discover-story-footer {
    flex-direction: column;
    align-items: flex-start;
  }
}
.discover-story-component .discover-story-footer .comments-button {
  margin-left: 4rem;
}
@media screen and (max-width: 768px) {
  .discover-story-component .discover-story-footer .comments-button {
    margin-left: 0;
  }
}
.discover-story-component .discover-story-footer .comments-button,
.discover-story-component .discover-story-footer .share-container {
  padding: 6px 12px;
  border-radius: 1rem;
}
@media screen and (max-width: 768px) {
  .discover-story-component .discover-story-footer .comments-button,
  .discover-story-component .discover-story-footer .share-container {
    padding: 6px 0;
  }
}
.discover-story-component .discover-story-footer .comments-button:hover,
.discover-story-component .discover-story-footer .share-container:hover {
  background-color: var(--pf-light-2);
}
.discover-story-component .discover-story-footer .share-container {
  margin-left: auto;
}
.discover-story-component .discover-story-footer .zero-comments {
  color: black;
}
.discover-story-component .discover-story-footer .share-container {
  color: gray;
}
