.chat-prompt {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.chat-prompt .chat-prompt-content {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  flex: 1;
}
@media screen and (max-width: 768px) {
  .chat-prompt .chat-prompt-content {
    margin: 0;
  }
}
.chat-prompt .chat-prompt-content .chat-prompt-title {
  margin-left: auto;
  font-size: 20px;
  font-weight: 600;
  color: #212529;
}
.chat-prompt .chat-prompt-content .user-input {
  background-color: #3C6CE9;
  color: #FFFFFF;
  border-radius: 10px 0 10px 10px;
  padding: 26px 26px;
  max-width: 70%;
  word-wrap: break-word;
  box-shadow: -1px 2px 10px 0px rgba(0, 0, 0, 0.25);
  margin-top: 10px;
  /* Add space above the bubble */
  margin-bottom: 10px;
  /* Add space below the bubble */
  margin-left: auto;
}
