.comment-component {
  position: relative;
  margin-bottom: 3rem;
  transition: background-color 1.5s;
}
.comment-component.targetted {
  background-color: hsl(223, 50%, 92%);
}
.comment-component .date {
  position: absolute;
  margin-top: 12px;
  right: 0;
  color: #212529;
  /* Caption 2 */
  font-family: "DM Sans";
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}
.comment-component .comment-container {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
.comment-component .comment-container .avatar {
  width: 60px;
  height: auto;
}
.comment-component .comment-container .comment-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: calc(48px / 4);
}
.comment-component .comment-container .comment-body h5 {
  color: #212529;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.056px;
}
.comment-component .comment-container .comment-body .comment-footer {
  display: inline-flex;
  gap: 5rem;
}
.comment-component .comment-container .comment-body .comment-footer .rating {
  display: flex;
  gap: 0.5rem;
}
.comment-component .comment-container .comment-body .comment-footer .rating svg {
  fill: #e1e1e1;
  stroke: none;
  cursor: pointer;
  transition: fill 250ms cubic-bezier(0.1, 0.1, 0, 1);
}
.comment-component .comment-container .comment-body .comment-footer .rating .upvote svg:hover {
  fill: var(--pf-blue);
}
.comment-component .comment-container .comment-body .comment-footer .rating .downvote svg {
  transform: rotate(180deg);
}
.comment-component .comment-container .comment-body .comment-footer .rating .downvote svg:hover {
  fill: var(--pf-pink);
}
.comment-component .comment-container .comment-body .comment-footer .rating .value {
  font-weight: 500;
}
.comment-component .comment-container .comment-body .comment-footer .reply-button {
  margin-top: 7px;
  align-items: center;
  color: #3C6CE9;
  /* Button 2 */
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  text-decoration-line: underline;
  cursor: pointer;
}
.comment-component .comment-container .comment-body .comment-footer .reply-button:hover {
  color: #2e54b4;
}
.comment-component .comment-container .comment-body .add-reaction-modal {
  z-index: 1000;
}
.comment-component .comment-container .comment-body .reaction-button {
  box-sizing: border-box;
  margin-right: 10px;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  background: #F2F2F2;
  border: 1px solid #DCDCDC;
}
.comment-component .comment-container .comment-body .reaction-button:hover {
  opacity: 80%;
}
.comment-component .comment-container .comment-body .reaction-button-selected {
  box-sizing: border-box;
  margin-right: 10px;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
  border: 1px solid #527EF0;
  background: #CFDDFF;
}
.comment-component .comment-container .comment-body .reaction-button-selected:hover {
  opacity: 80%;
}
.comment-component .options-menu-toggle {
  display: none;
}
.comment-component .options-menu-toggle:checked ~ .options-menu-container ul.options-menu {
  display: flex;
  position: absolute;
  right: 0;
  list-style-type: none;
}
.comment-component .options-menu-container {
  position: relative;
  margin-bottom: -30px;
  left: 97%;
  top: -30px;
  width: 0;
}
.comment-component .options-menu-container .options-menu-button {
  cursor: pointer;
}
.comment-component .options-menu-container .options-menu {
  display: none;
  background: white;
  padding: 5px;
  margin-bottom: 20px;
  margin-top: -20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  white-space: nowrap;
  z-index: 1;
}
.comment-component .options-menu-container .options-menu li {
  color: #527EF0;
  border: none;
  background: white;
  cursor: pointer;
}
