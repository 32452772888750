.chatSuggestion-container {
  width: 100%;
}
.chatSuggestion-container .chatSuggestion-long-container {
  margin-left: 72px;
}
@media screen and (max-width: 768px) {
  .chatSuggestion-container .chatSuggestion-long-container {
    margin-left: auto;
  }
}
.chatSuggestion-container .chatSuggestion-long-container .chatSuggestion-long {
  padding-left: 0;
  max-width: 75%;
}
@media screen and (max-width: 768px) {
  .chatSuggestion-container .chatSuggestion-long-container .chatSuggestion-long {
    max-width: 99%;
  }
}
.chatSuggestion-container .chatSuggestion-long-container .chatSuggestion-long li {
  list-style: none;
  width: 100%;
  border: 1px grey solid;
  border-radius: 5px;
  margin: 10px 2px;
  background-color: #dce6ff;
}
.chatSuggestion-container .chatSuggestion-long-container .chatSuggestion-long li button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  text-decoration: none;
  color: black;
  border: grey;
  background-color: #dce6ff;
  border-radius: 5px;
}
.chatSuggestion-container .chatSuggestion-long-container .chatSuggestion-long li button:hover {
  outline: 2px solid #81a0f1;
}
.chatSuggestion-container .chatSuggestion-long-container .chatSuggestion-long li button span {
  text-align: start;
}
.chatSuggestion-container .chatSuggestion-long-container .chatSuggestion-long li button img {
  margin-left: 15px;
}
.chatSuggestion-container .chatSuggestion-short {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  padding-left: 0;
  gap: 1.5rem;
}
.chatSuggestion-container .chatSuggestion-short li {
  list-style: none;
  width: 30vw;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px #6E6E6E solid;
  border-radius: 7px;
  margin: 10px 5px;
}
.chatSuggestion-container .chatSuggestion-short li:hover {
  outline: 1px solid #81a0f1;
  border: 1px solid #81a0f1;
}
.chatSuggestion-container .chatSuggestion-short li button {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none;
  color: black;
  border: #6E6E6E;
  background-color: white;
  border-radius: 7px;
}
.chatSuggestion-container .chatSuggestion-short li button span {
  text-align: start;
  margin-right: 45px;
}
.chatSuggestion-container .chatSuggestion-short li img {
  margin-left: 15px;
}
@media screen and (max-width: 768px) {
  .chatSuggestion-container .chatSuggestion-short li {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .chatSuggestion-container .chatSuggestion-short {
    flex-direction: column;
  }
}
