.commentReply-container {
  border-left: 1px black solid;
  margin-left: 75px;
  height: 65px;
  display: flex;
}

.commentReply-body {
  width: 100%;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.commentReply-body .commentReply-top form{
  display: flex;
}

.commentReply-body .commentReply-top form textarea {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  width: 90%;
  border: none;
  background-color: white;
  z-index: 2;
}

.commentReply-submit {
  border: none;
  background: none;
}


.commentReply-body .commentReply-bottom {
  margin-top: 5px;
  font-size: 10px;
  color: blue;
  font-style: italic;
  z-index: 2;
}