.stories-page {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
@media screen and (max-width: 992px) {
  .stories-page {
    flex-direction: column;
  }
}
.stories-page .back-arrow {
  margin-top: 32px;
  margin-right: 1rem;
  cursor: pointer;
}
.stories-page .story-section {
  width: 60%;
  gap: 40px;
}
@media screen and (max-width: 992px) {
  .stories-page .story-section {
    width: 100%;
  }
}
.stories-page .story-section .story-footer {
  display: flex;
  justify-content: space-between;
  margin: 25px 0px;
}
@media screen and (max-width: 992px) {
  .stories-page .story-section .story-footer {
    flex-wrap: wrap;
    gap: 20px;
  }
}
.stories-page .story-section .story-footer .story-footer-left {
  display: flex;
  align-items: center;
  gap: 3rem;
}
.stories-page .related-stories-section {
  margin-left: 8rem;
}
@media screen and (max-width: 992px) {
  .stories-page .related-stories-section {
    margin-left: 0;
  }
}
.stories-page .related-stories-section a.related-story {
  display: flex;
  height: auto;
  width: 316px;
  border-bottom: 2px solid #6E6E6E;
  flex-direction: column;
  gap: 20px;
  padding: 30px 8px;
  cursor: pointer;
  transition: background-color 0.25s ease-in-out;
  text-decoration: none;
}
.stories-page .related-stories-section a.related-story:hover {
  background-color: var(--pf-light-2);
}
.stories-page .related-stories-section a.related-story:last-child {
  border-bottom: none;
}
.stories-page .related-stories-section a.related-story .related-story-header {
  display: flex;
}
.stories-page .related-stories-section a.related-story .related-story-header .avatar {
  width: 60px;
  height: 60px;
  border: 1px solid #DCDCDC;
  margin-right: 1rem;
}
.stories-page .related-stories-section a.related-story .related-story-header .header-text {
  display: flex;
  align-items: center;
  color: #212529;
  /* Caption 1 */
  font-family: "DM Sans";
  font-size: 15px;
  font-weight: 400;
}
.stories-page .related-stories-section a.related-story .related-story-body {
  color: #212529;
  /* Subtitle 1 */
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.03px;
}
.stories-page .related-stories-section a.related-story .related-story-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.stories-page .related-stories-section a.related-story .related-story-footer .reaction-button:hover {
  outline: none;
}
.comment-button {
  color: #3C6CE9;
  /* Button 2 */
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-decoration-line: underline;
}
.comment-button.story {
  margin-left: rem;
}
.comment-button.related-story {
  margin-left: 12.5rem;
}
.comment-button.zero-comments {
  color: black;
}
