.resource-preview {
  width: 30%;
  min-width: 0;
  max-width: 30%;
  display: flex;
  flex-direction: column;
  border: 1px solid #6E6E6E;
  background: #FFF;
  border-radius: 24px;
}
@media screen and (max-width: 768px) {
  .resource-preview {
    width: auto;
    max-width: 250px;
  }
}
.resource-preview > a {
  text-decoration: none;
  color: var(--pf-dark-2);
  padding: 21px;
  border-radius: 24px;
}
.resource-preview > a .resource-header {
  display: flex;
  align-items: center;
}
.resource-preview > a .resource-header .avatar {
  border-radius: 0%;
  width: 2.5rem;
  height: 2.5rem;
}
.resource-preview > a .resource-header .domain-name {
  margin-left: 10px;
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.resource-preview > a .resource-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 20px;
  overflow: hidden;
  letter-spacing: 0.056px;
  line-height: normal;
}
.resource-preview > a .resource-footer {
  display: flex;
  align-items: flex-end;
  margin-top: auto;
  white-space: nowrap;
  overflow: hidden;
}
.resource-preview > a .resource-footer .author {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}
.resource-preview > a:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}
