.career-component .job-title-container {
  margin-top: 15px;
}
.career-component .job-title-container .title-and-tabs-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 50px;
  margin-bottom: 10px;
}
.career-component .job-title-container .title-and-tabs-container #career-tabs {
  background: hsl(0, 0%, 85%);
  border-radius: 10px;
  margin-bottom: auto;
}
.career-component .job-title-container .title-and-tabs-container #career-tabs .nav-link:not(.active) {
  color: #939393;
}
.career-component .job-title-container .title-and-tabs-container #career-tabs .nav-item .nav-link {
  border-radius: 10px;
  width: 150px;
  display: flex;
  justify-content: center;
}
.career-component .job-title-container .title-and-tabs-container #career-tabs .nav-link.active {
  background: var(--pf-brown);
}
@media screen and (max-width: 767px) {
  .career-component .job-title-container .title-and-tabs-container #career-tabs .nav-item button {
    padding: 0.5rem;
    font-size: 1rem;
    width: 100px !important;
  }
}
