.faq-card-component {
  --spotlight-background: #FFA5E2;
  --spotlight-text: #600041;
  /* Handle */
}
.faq-card-component ::-webkit-scrollbar {
  width: 6px;
}
.faq-card-component ::-webkit-scrollbar-track {
  background-color: transparent;
}
.faq-card-component ::-webkit-scrollbar-thumb {
  background: #777;
  border-radius: 4px;
}
.faq-card-component ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.faq-card-component .card-body {
  background: white;
  padding: 0.75em;
}
.faq-card-component .selected {
  box-shadow: 1px 1px 2px 1px var(--pf-light-blue);
  border-radius: 10px;
}
.faq-card-component .company-logo img {
  border-radius: 4px;
}
.faq-card-component .preview-card .max-icon,
.faq-card-component .expanded-card .max-icon,
.faq-card-component .discover-card .max-icon {
  opacity: 0.4;
}
.faq-card-component .preview-card .max-icon,
.faq-card-component .expanded-card .max-icon,
.faq-card-component .discover-card .max-icon,
.faq-card-component .preview-card .min-icon,
.faq-card-component .expanded-card .min-icon,
.faq-card-component .discover-card .min-icon {
  min-width: 24px;
  z-index: 1;
}
.faq-card-component .preview-card:hover .max-icon,
.faq-card-component .expanded-card:hover .max-icon,
.faq-card-component .discover-card:hover .max-icon {
  opacity: 0.8;
}
.faq-card-component .preview-card,
.faq-card-component .discover-card {
  cursor: pointer;
  border: 1px solid #B8B8B8;
  border-radius: 10px;
}
.faq-card-component .preview-card .preview-head,
.faq-card-component .discover-card .preview-head {
  display: flex;
  justify-content: space-between;
  margin-left: 0.5rem;
}
.faq-card-component .preview-card .preview-head .date-posted,
.faq-card-component .discover-card .preview-head .date-posted {
  font-size: 12px;
  color: #8E8E8E;
}
.faq-card-component .preview-card .responses-container,
.faq-card-component .discover-card .responses-container {
  height: 125px;
  padding: 0.5em 3em 1.5em 0.5em;
  font-size: 14px;
  mask-image: linear-gradient(180deg, #000 50%, transparent);
  -webkit-mask-image: linear-gradient(180deg, #000 50%, transparent);
  overflow-y: hidden;
}
.faq-card-component .preview-card .responses-container .question,
.faq-card-component .discover-card .responses-container .question {
  font-size: 16px;
}
.faq-card-component .preview-card .responses-container .answer,
.faq-card-component .discover-card .responses-container .answer {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}
.faq-card-component .preview-card.selected .card-footer,
.faq-card-component .discover-card.selected .card-footer {
  background: var(--pf-blue);
}
.faq-card-component .preview-card .card-footer,
.faq-card-component .discover-card .card-footer {
  display: flex;
  align-items: center;
}
.faq-card-component .preview-card .card-footer .company-logo img,
.faq-card-component .discover-card .card-footer .company-logo img {
  margin-left: 1em;
  width: 32px;
  height: auto;
}
.faq-card-component .preview-card .card-footer .official-title,
.faq-card-component .discover-card .card-footer .official-title {
  margin-left: 1rem;
  margin-right: 1rem;
}
.faq-card-component .expanded-card {
  border: 1px solid #B8B8B8;
  border-radius: 10px;
  width: auto;
  display: flex;
  flex-direction: column;
  /* Handle */
}
.faq-card-component .expanded-card ::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}
.faq-card-component .expanded-card ::-webkit-scrollbar-track {
  background-color: transparent;
}
.faq-card-component .expanded-card ::-webkit-scrollbar-thumb {
  background: #777;
  border-radius: 4px;
}
.faq-card-component .expanded-card ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.faq-card-component .expanded-card .x-expanded-card {
  display: none;
}
.faq-card-component .expanded-card .min-icon,
.faq-card-component .expanded-card .arrow-right {
  flex: 0 0 24px;
  margin-bottom: auto;
  transform: translateX(-50%);
  opacity: 0.6;
  cursor: pointer;
}
.faq-card-component .expanded-card .min-icon:hover,
.faq-card-component .expanded-card .arrow-right:hover {
  opacity: 0.8;
}
.faq-card-component .expanded-card .arrow-right {
  margin-left: auto;
  margin-right: 1rem;
  color: white;
}
.faq-card-component .expanded-card .card-header {
  background: var(--pf-blue);
  color: white;
  border-radius: 6px;
  padding: 10px 0px 10px 15px;
}
.faq-card-component .expanded-card .card-header .header-container {
  display: flex;
  align-items: center;
}
.faq-card-component .expanded-card .card-header .header-container .company-logo img {
  align-self: center;
  width: 48px;
  height: auto;
  margin-right: 1rem;
}
.faq-card-component .expanded-card .card-header .header-container .poster-role {
  font-size: 20px;
  padding-right: 1rem;
}
.faq-card-component .expanded-card .card-header .header-container .poster-name {
  opacity: 0.66;
  text-align: bottom;
}
.faq-card-component .expanded-card .tags-date-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
  height: 45px;
}
.faq-card-component .expanded-card .tags-date-container .tags {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.faq-card-component .expanded-card .tags-date-container .tags .tag {
  color: black;
  background: #DFE4F8;
  border: 2px solid #C5CBE5;
  border-radius: 50rem;
  padding: 10px 15px 10px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
}
.faq-card-component .expanded-card .tags-date-container .date-posted {
  font-size: 14px;
  text-align: right;
  color: var(--pf-lavender);
  align-self: flex-start;
}
.faq-card-component .expanded-card .spotlight-header {
  background-color: var(--spotlight-background);
  color: var(--spotlight-text);
}
.faq-card-component .expanded-card .spotlight-header .header-container {
  display: flex;
  flex-direction: row-reverse;
}
.faq-card-component .expanded-card .spotlight-header .header-container .company-logo {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex: 1 0 35%;
  flex-wrap: wrap;
  gap: 1rem;
  overflow-x: scroll;
  margin-left: auto;
  margin-right: 1rem;
}
.faq-card-component .expanded-card .spotlight-header .header-container .company-logo img {
  margin-right: 0;
}
.faq-card-component .expanded-card .spotlight-header .header-container .poster-role {
  font-weight: 600;
}
.faq-card-component .expanded-card .responses-container {
  height: 500px;
  padding: 0 1.5em 0;
  overflow-y: auto;
}
.faq-card-component .expanded-card .responses-container .answer {
  padding-left: 1rem;
  line-height: 1.6em;
  letter-spacing: 0.005em;
}
.faq-card-component .expanded-card .responses-container .answer li {
  list-style: disc;
  margin: 0.5rem 0 0.5rem 1rem;
}
.faq-card-component .expanded-card .responses-container .intro {
  display: flex;
  align-items: flex-start;
  margin-top: 0.5rem;
}
.faq-card-component .expanded-card .responses-container .intro .spotlight-avatar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
}
.faq-card-component .expanded-card .responses-container .intro .spotlight-avatar-container img {
  width: 6rem;
  height: 6rem;
  border-radius: 6px;
}
.faq-card-component .expanded-card .responses-container .intro .spotlight-avatar-container .spotlight-name {
  margin-top: 0.5rem;
  color: var(--spotlight-text);
  text-align: center;
}
.faq-card-component .expanded-card .responses-container .intro .answer {
  padding-left: 0;
}
.faq-card-component .expanded-card .responses-container .intro .answer li {
  list-style: none;
  margin-left: 0;
}
.faq-card-component .expanded-card .responses-container .spotlight-responses .response {
  flex: 1 1 40%;
}
.faq-card-component .expanded-card .responses-container .spotlight-responses .response .answer {
  padding-left: 0;
}
.faq-card-component .expanded-card .responses-container .spotlight-responses .response .answer li {
  list-style: none;
  margin-left: 0;
}
.faq-card-component .expanded-card .responses-container .spotlight-responses .response .answer li li {
  list-style: disc;
  margin-left: 1rem;
}
.faq-card-component .spotlight-card {
  background-color: var(--spotlight-background);
  color: var(--spotlight-text);
}
.faq-card-component .spotlight-card .preview-head .date-posted {
  color: var(--spotlight-text);
}
.faq-card-component .spotlight-card .responses-container {
  height: auto;
  padding-right: 0.5em;
  padding-bottom: 0;
  mask-image: none;
  -webkit-mask-image: none;
}
.faq-card-component .spotlight-card .responses-container .spotlight-title {
  font-weight: 1000;
  font-size: 18px;
}
.faq-card-component .spotlight-card .responses-container .spotlight-hook {
  color: var(--spotlight-text);
  padding-right: 1em;
}
.faq-card-component .spotlight-card .responses-container .spotlight-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
.faq-card-component .spotlight-card .responses-container .spotlight-footer #spotlight-star {
  width: 40px;
}
.faq-card-component .spotlight-card .responses-container .spotlight-footer .spotlight-logos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0.5em;
}
.faq-card-component .spotlight-card .responses-container .spotlight-footer .spotlight-logos img {
  border-radius: 4px;
  width: 32px;
  margin: 1px 5px 1px 5px;
}
.faq-card-component .spotlight-card .responses-container .spotlight-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #B8007D;
}
.faq-card-component .spotlight-card .responses-container .spotlight-avatar img {
  width: 128px;
  height: 128px;
  border-radius: 4px;
  margin: 1px 5px 1px 5px;
  border: 1px solid #B8B8B8;
}
.faq-card-component .discover-card {
  display: flex;
  flex-direction: column;
}
.faq-card-component .discover-card .responses-container {
  height: 150px;
}
.faq-card-component .discover-card .card-footer {
  background: var(--pf-blue);
}
.faq-card-component .question {
  color: var(--pf-dark);
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}
.faq-card-component .card-footer {
  font-size: 18px;
  color: white;
  background: var(--pf-light-blue);
  border-radius: 10px;
  border: transparent;
  padding: 10px 0px;
}
.faq-card-component .card-footer .official-title {
  font-weight: bold;
}
@media screen and (max-width: 1200px) {
  .faq-card-component .preview-card {
    margin-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .faq-card-component .expanded-card .x-expanded-card {
    display: block;
    position: absolute;
    color: var(--pf-dark);
    top: 0px;
    right: 0px;
    border-radius: 50rem;
    padding: 0.2rem;
    background: lightgray;
    z-index: 22;
  }
  .faq-card-component .expanded-card .card-header .header-container .poster-role {
    font-size: 18px;
  }
  .faq-card-component .expanded-card .card-header .header-container .poster-name {
    font-size: 16px;
  }
  .faq-card-component .expanded-card .tags-date-container .tags .tag {
    padding: 4px 8px;
  }
  .faq-card-component .expanded-card .tags-date-container .tags .tag:last-child {
    margin-right: 0;
  }
  .faq-card-component .expanded-card .date-posted {
    font-size: 12px;
  }
  .faq-card-component .expanded-card .responses-container .question {
    font-size: 16px;
  }
  .faq-card-component .expanded-card .responses-container .spotlight-avatar img {
    width: 64px;
    height: 64px;
  }
  .faq-card-component .expanded-card .spotlight-header .header-container {
    flex-direction: column-reverse;
    align-items: center;
  }
  .faq-card-component .expanded-card .spotlight-header .company-logo {
    margin: 1em auto 0 0;
  }
  .faq-card-component .expanded-card .spotlight-header .company-logo img {
    width: 32px;
  }
  .faq-card-component .preview-card .responses-container {
    padding-top: 0;
  }
  .faq-card-component .preview-card .responses-container .question {
    font-size: 14px;
  }
  .faq-card-component .preview-card .responses-container .response {
    font-size: 12px;
  }
  .faq-card-component .preview-card .card-footer {
    font-size: 16px;
  }
}
