.community-landing {
  width: 100%;
  color: white;
  align-self: stretch;
  min-height: 1000px;
}
.community-landing .community-header .subdomain-banner {
  background-color: var(--pf-bkg);
}
.community-landing .community-landing-header-active {
  width: 100%;
  height: 222px;
  position: absolute;
  z-index: 9;
  background: var(--pf-bkg);
}
.community-landing .logo-container {
  display: flex;
  justify-content: center;
  margin: 2rem auto;
}
.community-landing .logo-container .community-logo {
  border-radius: 24px;
  width: 114px;
  height: 114px;
  z-index: 10;
}
.community-landing .ai-chat {
  z-index: 10;
  position: relative;
}
