@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  60% {
    transform: scaleY(1.1);
  }
  80% {
    transform: scaleY(0.95);
  }
  100% {
    transform: scaleY(1);
  }
}
.field-component h2 {
  font-size: 40px;
}
.field-component li,
.field-component ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.field-component .rotate-180 {
  transform: rotate(180deg);
}
.field-component .fields-body .subfield {
  margin-top: 20px;
  width: 95%;
}
.field-component .fields-body .subfield ul {
  columns: 2;
}
.field-component .fields-body .subfield .subfield-header {
  display: inline-block;
  margin-bottom: 10px;
  user-select: none;
}
.field-component .fields-body .subfield .subfield-header:hover {
  cursor: pointer;
}
.field-component .fields-body .subfield .subfield-header svg {
  margin-left: 5px;
}
.field-component .fields-body .subfield .subfield-careers {
  width: 100%;
  display: none;
  animation: growDown 250ms ease-in-out;
  transform-origin: top center;
}
.field-component .fields-body .subfield .subfield-careers.show-careers {
  display: block;
}
.field-component .fields-body .subfield .subfield-careers .career {
  width: 75%;
  position: relative;
  padding-left: 7.5px;
  font-size: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.field-component .fields-body .subfield .subfield-careers .career:hover {
  background: hsl(0, 0%, 90%);
}
.field-component .fields-body .subfield .subfield-careers .career:hover .arrow-right {
  display: inline-block;
}
.field-component .fields-body .subfield .subfield-careers .career .arrow-right {
  display: none;
  float: right;
  margin-right: 35px;
  margin-top: 6px;
  width: 30px;
  height: 18px;
}
@media screen and (max-width: 767px) {
  .field-component .fields-body .subfield ul {
    columns: 1;
  }
  .field-component .fields-body .subfield .subfield-careers .career {
    width: 100%;
  }
}
