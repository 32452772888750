.namePopup {
  width: 577px;
  height: 539px;
  outline: none;
  border: 0;
  gap: 1.125rem;
  border-radius: 1rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  overflow: auto;
  display: flex;
  justify-content: center;
  /* Center horizontally */
  align-items: center;
  /* Center vertically */
}
.namePopup .layout {
  height: 100%;
  width: 100%;
  display: grid;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.namePopup .layout .buttonSection {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
}
.namePopup .layout .buttonSection .blueText {
  color: #3C6CE9;
  font-family: "DM Sans";
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.00188rem;
  margin: 0;
}
.namePopup .layout .buttonSection .blueText.firstNameText {
  font-size: 1rem;
  margin-left: 0.5rem;
}
.namePopup .layout .buttonSection .nameSection {
  display: flex;
  flex-direction: column;
}
.namePopup .layout .buttonSection .nameSection .nameLabel {
  color: #3C6CE9;
  margin-bottom: 0.25rem;
  margin-top: 0.5rem;
}
.namePopup .layout .buttonSection .nameSection .nameInput {
  width: 25rem;
  height: 3.5rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #A8A8A8;
}
.namePopup .layout .buttonSection .nameSection .getStartedButton {
  width: 25rem;
  height: 3.5rem;
  padding: 1rem;
  margin-top: 16px;
  border-radius: 0.5rem;
  background-color: #3C6CE9;
  border: 0px;
  color: #fefefe;
}
.namePopup .layout .buttonSection .nameSection .getStartedButton:hover {
  background-color: var(--pf-light-blue-2);
}
.namePopup .layout .buttonSection .nameSection .checkboxContainer {
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.namePopup .layout .buttonSection .nameSection .checkboxContainer input[type="checkbox"] {
  width: 20px;
  height: 20px;
}
.namePopup .layout .buttonSection .nameSection .checkboxContainer input[type="checkbox"]:hover {
  cursor: pointer;
  user-select: none;
  outline: 1px solid #3c6ce9;
}
.namePopup .layout .buttonSection .nameSection .checkboxContainer input[type="checkbox"]:checked:after {
  content: "\2713";
  /* Unicode character for checkmark */
  position: absolute;
  top: -20%;
  left: 10%;
}
