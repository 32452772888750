.discover-component {
  margin: 0 min(12rem, 10vw) 4rem;
}
.discover-component .main-header {
  margin-top: 1rem;
  font-size: calc(1.4rem + 0.9vw);
}
.discover-component .main-description {
  color: var(--pf-gray);
  font-size: calc(1rem + 0.6vw);
}
.discover-component .featured .section-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.discover-component .featured .section-header button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}
.discover-component .featured .section-header button:first-child {
  margin-left: 2rem;
}
.discover-component .featured .section-header svg {
  color: #b6b6b6;
  user-select: none;
}
.discover-component .featured .section-header svg:hover {
  color: var(--pf-dark);
}
.discover-component .featured-cards-container {
  overflow: hidden;
  width: 100vw;
  position: relative;
  right: min(12rem, 10vw);
  z-index: 5;
}
.discover-component .featured-cards-container .carousel {
  --index: 1;
  --transform-transition-time: 0.5s;
  display: flex;
  align-items: flex-start;
  gap: 2vw;
}
.discover-component .featured-cards-container .carousel .faq-card-component {
  flex: 1 0 40vw;
  max-width: 40vw;
  height: 100%;
  /* note: 100vw includes scrollbar width, so card on right will display slightly less than the left */
  transform: translateX(calc((var(--index) - 1) * (-100% - 2vw) - (100% - (100vw - 100% - (2 * 2vw)) / 2)));
}
.discover-component .featured-cards-container .carousel .faq-card-component,
.discover-component .featured-cards-container .carousel .faq-card-component *:not(svg, a) {
  transition: transform var(--transform-transition-time) ease-in-out, opacity 0.75s cubic-bezier(0.2, 0, 0.4, 1);
}
.discover-component .featured-cards-container .carousel .faq-card-component.focused .card-footer {
  background: var(--pf-blue);
}
.discover-component .featured-cards-container .carousel .faq-card-component:not(.focused) {
  cursor: pointer;
}
.discover-component .featured-cards-container .carousel .faq-card-component:not(.focused) * {
  opacity: 0.85;
}
.discover-component .featured-cards-container .carousel .faq-card-component:not(.focused) .card-footer {
  background: var(--pf-light-blue);
}
.discover-component .featured-cards-container .carousel::-webkit-scrollbar {
  width: 0px;
}
.discover-component .featured-cards-container .carousel::-webkit-scrollbar-thumb {
  background: transparent;
}
@media screen and (max-width: 767px) {
  .discover-component .featured-cards-container .carousel {
    gap: 3vw;
  }
  @supports not (gap: 3vw) {
    .discover-component .featured-cards-container .carousel .faq-card-component {
      margin-right: 3vw;
    }
    .discover-component .featured-cards-container .carousel .faq-card-component:last-child {
      margin-right: 0;
    }
  }
  .discover-component .featured-cards-container .carousel .faq-card-component {
    flex: 1 0 80%;
    max-width: 80%;
    transform: translateX(calc((var(--index) - 1) * (-100% - 3vw) - (100% - (100vw - 100% - (2 * 3vw)) / 2)));
  }
}
.discover-component .section-header {
  position: relative;
  margin-top: 2rem;
  color: #494949;
}
.discover-component .section-header::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 2rem;
  border-bottom: 1px solid black;
}
.discover-component .section-name {
  color: var(--pf-blue);
}
.discover-component .content-section .cards-container {
  padding-top: 1rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem 4rem;
}
@media screen and (max-width: 767px) {
  @supports not (gap: 2rem 4rem) {
    .discover-component .content-section .cards-container .faq-card-component {
      margin-bottom: 2rem;
    }
    .discover-component .content-section .cards-container .faq-card-component:last-child {
      margin-bottom: 0;
    }
  }
}
.discover-component .content-section .cards-container .faq-card-component {
  flex: 1 1 40%;
}
.discover-component .content-section .see-more {
  border: transparent;
  background: none;
  font-size: 24px;
  color: #4D4D4D;
  float: right;
  font-style: italic;
}
.discover-component .faq-card-component .expanded-card {
  height: 500px;
}
.discover-component .faq-card-component .expanded-card .x-expanded-card {
  display: none;
}
