.popover-container {
  position: relative;
}
.popover-content {
  position: absolute;
  bottom: calc(100% + 10px);
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  border: 1px solid #cccccc;
  color: #9f9f9f;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.4);
}
