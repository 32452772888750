.discoverSearch-container {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-radius: 5px;
  box-shadow: 1px 2px 10px 0px rgba(33, 37, 41, 0.2);
  background-color: #FFFFFF;
}
.discoverSearch-container .search-icon {
  margin-left: 20px;
  cursor: pointer;
}
.discoverSearch-container form {
  width: 100%;
  border-radius: 5px;
  margin: 0 10px;
  height: 100%;
}
.discoverSearch-container form input {
  height: 100%;
  border: none;
  width: 100%;
  outline: none;
}
