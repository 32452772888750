.discoverv2-component .discover-main {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 992px) {
  .discoverv2-component .discover-main {
    flex-direction: column;
  }
}
.discoverv2-component .discover-main .community-logo {
  border-radius: 36px;
  width: 200px;
  height: 200px;
}
.discoverv2-component .discover-main .filters-contribute {
  display: flex;
  flex-direction: column;
  flex: 0 1 25%;
}
.discoverv2-component .discover-main .filters-contribute .contribute-button {
  align-self: flex-start;
  background-color: #3c6ce9;
  border-radius: 0.5rem;
  color: white;
  padding: 1rem 3rem;
  transition: all 250ms cubic-bezier(0.1, 0.1, 0, 1);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.2px;
  position: absolute;
}
.discoverv2-component .discover-main .filters-contribute .contribute-button:hover {
  background-color: hsl(223, 84%, 73%);
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container {
  margin-top: 3rem;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filters-heading {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
  margin-top: 4rem;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category {
  color: #000;
  width: 66%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .filter-title {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .filter-category-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  padding-right: 9px;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .filter-category-container::-webkit-scrollbar {
  width: 9px;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .filter-category-container::-webkit-scrollbar-track {
  background-color: #DEDEDE;
  border-radius: 4.5px;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .filter-category-container::-webkit-scrollbar-thumb {
  background-color: #3C6CE9;
  border-radius: 4.5px;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .filter-category-container input[type="checkbox"] {
  appearance: none;
  flex: 0 0 14px;
  height: 14px;
  border-radius: 3px;
  border: 1px solid #212529;
  position: relative;
  background-color: white;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .filter-category-container input[type="checkbox"]:checked {
  background-color: #3c6ce9;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .filter-category-container input[type="checkbox"]:hover,
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .filter-category-container .filter-label:hover {
  cursor: pointer;
  user-select: none;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .filter-category-container input[type="checkbox"]:hover,
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .filter-category-container .filter-label:hover + input[type="checkbox"] {
  outline: 1px solid #3c6ce9;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .filter-category-container input[type="checkbox"]:checked:after {
  content: "\2713";
  /* Unicode character for checkmark */
  color: white;
  font-size: 13px;
  font-weight: bold;
  position: absolute;
  top: -20%;
  left: 10%;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .filter-category-container .filter-category-element {
  display: flex;
  align-items: center;
  gap: 6px;
  padding-left: 1px;
  width: 100%;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .filter-category-container .filter-label {
  color: black;
  line-height: normal;
  letter-spacing: 0.016px;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .search-box {
  display: flex;
  width: 100%;
  height: 35px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 1px 2px 11px 0px rgba(33, 37, 41, 0.2);
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .search-box input {
  color: #6e6e6e;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.024px;
  width: 100%;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .search-box input {
  flex: 1;
  border: none;
  outline: none;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .view-more-button {
  color: #3c6ce9;
  /* P3 */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.056px;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-category .view-more-button:hover {
  cursor: pointer;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-options {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 28px;
  gap: 36px;
  width: 100%;
}
.discoverv2-component .discover-main .filters-contribute .filters-dropdown-container .filter-results-by {
  margin-right: 1rem;
  font-weight: bold;
  font-size: 2rem;
}
.discoverv2-component .discover-main .stories-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 1 75%;
  margin-bottom: 2rem;
}
.discoverv2-component .discover-main .stories-section .sort-stories {
  align-self: flex-end;
}
.discoverv2-component .discover-main .stories-section .sort-stories .sort-dropdown {
  position: relative;
  user-select: none;
  font-size: 16px;
  letter-spacing: 0.016px;
  cursor: pointer;
}
.discoverv2-component .discover-main .stories-section .sort-stories .sort-dropdown button {
  background-color: transparent;
  border: none;
}
.discoverv2-component .discover-main .stories-section .sort-stories .sort-dropdown button .current-sort {
  color: #3c6ce9;
}
.discoverv2-component .discover-main .stories-section .sort-stories .sort-dropdown .sort-menu {
  position: absolute;
  padding-left: 0;
  right: 0;
  left: auto;
}
.discoverv2-component .discover-main .stories-section .sort-stories .sort-dropdown .sort-menu .sort-item {
  background-color: white;
  list-style: none;
  text-align: center;
  padding: 2px 1rem;
}
.discoverv2-component .discover-main .stories-section .sort-stories .sort-dropdown .sort-menu .sort-item:hover {
  background-color: #3c6ce9;
  color: white;
}
.discoverv2-component .discover-main .stories-section .stories {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
}
.discoverv2-component .discover-main .stories-section .stories .discover-story-component {
  margin-bottom: 2rem;
}
.discoverv2-component .discover-main .stories-section .pagination-container {
  width: 100%;
}
.discoverv2-component .discover-main .stories-section .pagination-container .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.discoverv2-component .discover-main .stories-section .pagination-container .pagination > button,
.discoverv2-component .discover-main .stories-section .pagination-container .pagination .space {
  width: 40px;
  height: 40px;
}
.discoverv2-component .discover-main .stories-section .pagination-container .pagination .page-numbers {
  margin: 0 1rem;
}
.discoverv2-component .discover-main .stories-section .pagination-container .pagination .page-numbers button {
  width: 2rem;
  height: 2rem;
}
.discoverv2-component .discover-main .stories-section .pagination-container .pagination button {
  background-color: transparent;
  border: none;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.027px;
  color: #212529;
  border-radius: 12px;
}
.discoverv2-component .discover-main .stories-section .pagination-container .pagination button.active {
  color: #3c6ce9;
  cursor: default;
  font-weight: bold;
}
.discoverv2-component .discover-main .stories-section .pagination-container .pagination button:hover:not(.active) {
  transition: background-color 0.1s ease-in-out;
  background-color: var(--pf-light-2);
}
.discoverv2-component .discover-main .loading-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 8rem;
  align-self: flex-start;
}
.discoverv2-component .community-header + .discover-main {
  margin-top: 10rem;
}
