.community-header .subdomain-banner {
  width: 100%;
  height: 350px;
  position: absolute;
  transform: translateY(-8rem);
  z-index: 0;
}
@media screen and (max-width: 767px) {
  .community-header .subdomain-banner {
    height: 400px;
  }
}
.community-header .subdomain-header {
  transform: translateY(30%);
  display: flex;
  position: relative;
  z-index: 10;
}
@media screen and (max-width: 767px) {
  .community-header .subdomain-header {
    transform: translateY(10%);
  }
}
.community-header .subdomain-header .community-logo {
  border-radius: 36px;
  width: 200px;
  height: 200px;
}
.community-header .subdomain-header .community {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-top: 2rem;
}
.community-header .subdomain-header .community .community-title {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .community-header .subdomain-header .community .community-title {
    align-items: center;
  }
}
.community-header .subdomain-header .community .community-title .title {
  vertical-align: bottom;
  font-size: 48px;
  letter-spacing: 0.6px;
  font-weight: bold;
  line-height: 0.75;
}
@media screen and (max-width: 767px) {
  .community-header .subdomain-header .community .community-title .title {
    line-height: 1.2;
    font-size: 44px;
    flex-wrap: wrap;
  }
}
.community-header .subdomain-header .community .community-title .links {
  display: flex;
  margin-left: 8px;
  gap: 8px;
}
.community-header .subdomain-header .community .community-title .links svg:hover,
.community-header .subdomain-header .community .community-title .links img:hover {
  transform: scale(1.1);
}
.community-header .subdomain-header .community .community-title .links img {
  height: auto;
}
.community-header .subdomain-header .community .community-description {
  font-size: 24px;
  font-weight: bold;
  margin-top: 24px;
}
@media screen and (max-width: 767px) {
  .community-header .subdomain-header .community .community-description {
    font-size: 22px;
  }
}
