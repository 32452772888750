.footer-component {
  position: relative;
  width: 100%;
  background-color: #3c3c3c;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  padding: 1.5rem 4rem;
  gap: 2rem;
}
.footer-component .list-unstyled {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-component .copyright-col {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-component .copyright-col .clearbit-attribution {
  margin-top: 2rem;
}
.footer-component .copyright-col .clearbit-attribution a {
  font-size: 12px;
  color: hsl(0, 0%, 90%);
}
.footer-component #copyright {
  color: white;
  align-self: flex-start;
}
.footer-component .arrow-up {
  color: white;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.footer-component .columns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4rem;
  width: 80%;
}
.footer-component .columns .col {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  white-space: nowrap;
}
.footer-component .columns .col .col-header {
  font-size: 1.1rem;
  color: hsl(0, 0%, 90%);
  font-weight: 500;
}
.footer-component .columns .col .col-items {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-component .columns .col .col-items .col-item a {
  text-decoration: none;
  color: hsl(0, 0%, 80%);
  font-size: 0.9rem;
}
.footer-component .columns .col .col-items .col-item a:hover {
  text-decoration: underline;
}
.footer-component .columns .col .col-items .col-item a svg,
.footer-component .columns .col .col-items .col-item a img {
  margin-left: 0.5rem;
}
@media screen and (max-width: 767px) {
  .footer-component {
    padding: 1rem 4rem;
    flex-direction: column-reverse;
  }
  .footer-component .columns {
    justify-content: space-between;
    width: 100%;
  }
  .footer-component .arrow-up {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .footer-component {
    padding: 1rem 2rem;
  }
  .footer-component .columns {
    gap: 2rem;
  }
}
