.stories-component {
  display: flex;
}
@media screen and (min-width: 767px) {
  .stories-component {
    /* Handle */
  }
  .stories-component ::-webkit-scrollbar {
    width: 6px;
  }
  .stories-component ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .stories-component ::-webkit-scrollbar-thumb {
    background: #777;
    border-radius: 4px;
  }
  .stories-component ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.stories-component .view-more-container {
  margin-top: 10px;
}
.stories-component .filter-results-bold {
  font-weight: bold;
}
.stories-component .filters-contribute {
  display: flex;
  justify-content: space-between;
}
.stories-component .filters-contribute .filters-dropdown-container {
  margin-top: 0.5rem;
  width: 260px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
.stories-component .filters-contribute .filters-dropdown-container .filter-category {
  margin-right: -0.5rem;
  font-weight: bold;
  font-size: 1.5rem;
  gap: 10px;
  display: inline-flex;
  flex-direction: column;
}
.stories-component .filters-contribute .filters-dropdown-container .filter-category .filter-category-element {
  display: block;
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 18px;
}
.stories-component .filters-contribute .filters-dropdown-container .filter-category .view-more-button {
  display: block;
  margin-bottom: 1rem;
  color: #527ef0cc;
  font-weight: 800;
  font-size: 16px;
}
.stories-component .filters-contribute .filters-dropdown-container .filter-category .view-more-button:hover {
  cursor: pointer;
}
.stories-component .filters-contribute .filters-dropdown-container .filter-options {
  display: flex;
  gap: 1.25rem;
  margin-top: 0.85rem;
  margin-right: 75px;
  flex-wrap: wrap;
  flex-direction: column;
}
.stories-component .filter-results-by {
  margin-right: 1rem;
  font-weight: bold;
  font-size: 2rem;
}
.dropdown {
  margin-right: 1rem;
}
.dropdown-item-checkbox {
  cursor: pointer;
  margin-right: 15px;
}
.dropdown-item {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropdown-menu {
  max-width: 100vw;
}
.dropdown-toggle {
  color: dimgray;
  background-color: #EDEDED;
  border-color: lightgray;
}
.contribute-button {
  float: right;
  align-self: center;
  padding: 0.5rem;
  border: none;
  border-radius: 10px;
  background-color: #527ef0cc;
  transition: all 250ms cubic-bezier(0.1, 0.1, 0, 1);
}
.contribute-button:hover {
  background-color: hsl(223, 84%, 73%);
}
.contribute-button .contribute-link {
  color: white;
  text-decoration: none;
}
.loading-container {
  margin-top: 1.5rem;
}
.selected-filters-container {
  width: stretch;
  margin: 15px 5px 0px 5px;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.selected-filters-container .filter {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
  background: #DFE4F8;
  border: 1px solid #C5CBE5;
  padding: 10px 15px 10px 18px;
  margin-left: 15px;
  font-size: 15px;
  font-weight: bold;
}
.selected-filters-container .filter svg {
  margin-bottom: 2px;
  margin-left: 8px;
  opacity: 0.75;
}
.selected-filters-container .filter svg:hover {
  opacity: 1;
}
.selected-filters-container .filter .filter-button {
  cursor: pointer;
}
.selected-filters-container .filter .filter-button:hover > svg {
  color: black;
  opacity: 1;
}
.selected-filters-container .clear-filters {
  background: none;
  border: none;
  text-decoration: underline;
  margin-right: 25px;
  margin-left: 25px;
  padding: 0.5rem;
}
.loading-stories {
  font-size: 1.25rem;
}
.no-stories {
  margin-top: 1.5rem;
}
.faq-container {
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
  --offset-top: 0px;
  height: calc(100vh - var(--offset-top));
}
.faq-container > div {
  margin-bottom: 1rem;
}
.faq-container .faq-preview-cards-container {
  flex: 1 1 250px;
  overflow-y: auto;
  align-self: stretch;
  padding: 0;
}
.faq-container .faq-preview-cards-container .faq-card-component {
  padding: 2px;
  margin-bottom: 1rem;
}
.faq-container .faq-preview-cards-container .faq-card-component:last-child {
  margin-bottom: 0;
}
.faq-container .expanded-card-modal {
  flex: 2 0 400px;
}
.faq-container .expanded-card-modal .faq-expanded-card-container {
  height: 100%;
  overflow-y: auto;
  margin: 0;
  padding-right: 4px;
}
.faq-container .expanded-card-modal .faq-expanded-card-container .faq-card-component {
  padding: 0;
}
.faq-container .expanded-card-modal .faq-expanded-card-container .faq-card-component .responses-container {
  height: 100%;
}
.faq-container .expanded-card-modal .faq-expanded-card-container .comments-container {
  margin-top: 1rem;
}
@media screen and (max-width: 767px) {
  .stories-component .title-and-tabs-container {
    font-size: 1rem;
  }
  .stories-component .filters-contribute {
    margin: 1rem 0;
    flex-direction: column;
  }
  .stories-component .filters-contribute .contribute-button {
    margin-top: 1rem;
  }
  .stories-component .filters-dropdown-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  .stories-component .filters-dropdown-container span {
    margin-right: 0;
  }
  .stories-component .filters-dropdown-container span:first-child {
    display: block;
  }
  .stories-component .filters-dropdown-container .dropdown-toggle {
    font-size: 14px;
  }
  .stories-component .selected-filters-container .filter {
    padding: 5px 10px 5px 9px;
  }
  .stories-component .faq-container {
    margin: 1rem 0;
    height: 100%;
  }
  .stories-component .faq-container ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  .stories-component .faq-container ::-webkit-scrollbar-thumb {
    background: #777;
  }
  .stories-component .faq-container .expanded-card-modal {
    display: none;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    margin-bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 102;
  }
  .stories-component .faq-container .expanded-card-modal .faq-expanded-card-container {
    position: fixed;
    z-index: 3;
    margin: 0 2rem;
    overflow-x: hidden;
  }
  .stories-component .faq-container .expanded-card-modal .faq-expanded-card-container .responses-container {
    overflow-y: visible;
  }
  .stories-component footer {
    display: flex;
  }
}
