.chatInput {
  position: sticky;
  bottom: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1.5rem;
  box-shadow: 1px 2px 25px 0px hsl(0, 0%, 75%);
  border-radius: 8px;
  background-color: white;
  user-select: none;
  cursor: text;
}
.chatInput .chatInput-sparkles {
  margin-right: 10px;
}
.chatInput .chatInput-submit {
  border: none;
  background: none;
}
.chatInput .inputBox {
  border: 0;
  resize: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 2rem;
  min-height: 2rem;
  outline: none;
  font-size: 1.5rem;
  overflow-y: hidden;
  padding: 0;
}
@media screen and (max-width: 768px) {
  .chatInput .inputBox {
    font-size: 15px;
    line-height: 2rem;
  }
}
.chatInput .inputBox::placeholder {
  color: #6E6E6E;
}
@media screen and (max-width: 420px) {
  .chatInput .inputBox::placeholder {
    line-height: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .chatInput {
    padding: 1.5rem 0.5rem;
  }
}
