.signinModal {
  width: 72.3rem;
  height: 44.3rem;
  padding: 0;
  outline: none;
  border: 0;
  gap: 1.125rem;
  border-radius: 1rem;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
}
@media screen and (max-width: 768px) {
  .signinModal {
    padding: 16px;
  }
}
.signinModal .layout {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 55% 1% 44%;
  padding: 3.75rem 4.5rem;
}
@media screen and (max-width: 768px) {
  .signinModal .layout {
    grid-template-columns: 100%;
    padding: 1rem;
  }
}
@media screen and (max-width: 1200px) and (min-width: 768px) {
  .signinModal .layout {
    padding: 2.75rem 3.5rem;
  }
}
.signinModal .closingButton {
  position: absolute;
  right: 1.12rem;
  top: 1.12rem;
  outline: none;
  border: 2px solid #527ef0;
  border-radius: 25%;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: transparent;
}
.signinModal .textContainer {
  user-select: none;
}
.signinModal .featureSection {
  padding-top: 1.75rem;
  padding-right: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media screen and (max-width: 768px) {
  .signinModal .featureSection {
    padding-right: 0;
    padding-bottom: 1.75rem;
  }
}
@media screen and (max-width: 1100px) and (min-width: 768px) {
  .signinModal .featureSection {
    padding-right: 2rem;
    overflow: scroll;
  }
}
.signinModal .buttonSection {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  padding: 2.75rem;
  padding-left: 4rem;
}
@media screen and (max-width: 768px) {
  .signinModal .buttonSection {
    padding: 1rem 0;
  }
}
@media screen and (max-width: 1100px) and (min-width: 768px) {
  .signinModal .buttonSection {
    padding: 2.75rem 0;
    padding-left: 2rem;
  }
}
.signinModal .buttonSection .magicLinkSection {
  display: flex;
  flex-direction: column;
}
.signinModal .buttonSection .magicLinkSection .emailLabel {
  color: #3C6CE9;
  margin-bottom: 0.25rem;
}
.signinModal .buttonSection .magicLinkSection .emailInput {
  width: 21.0625rem;
  height: 3.5rem;
  padding: 1rem 3rem 1rem 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #A8A8A8;
}
@media screen and (max-width: 1200px) {
  .signinModal .buttonSection .magicLinkSection .emailInput {
    width: auto;
  }
}
.signinModal .buttonSection .magicLinkSection .emailButton {
  width: 21.0625rem;
  height: 3.5rem;
  padding: 1rem 3rem;
  margin-top: 1rem;
  border-radius: 0.5rem;
  background-color: #3C6CE9;
  border: 0px;
  color: #fefefe;
}
.signinModal .buttonSection .magicLinkSection .emailButton:hover {
  background-color: var(--pf-light-blue-2);
}
@media screen and (max-width: 1200px) {
  .signinModal .buttonSection .magicLinkSection .emailButton {
    width: auto;
  }
}
.signinModal .headlineText {
  color: #3C6CE9;
  font-family: "DM Sans";
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
  margin: 0;
}
.signinModal .blueText {
  color: #3C6CE9;
  font-family: "DM Sans";
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.00188rem;
  margin: 0;
}
.signinModal .normalText {
  color: #212529;
  font-family: "DM Sans";
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
}
.signinModal .signinButton {
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  height: 3.5rem;
  width: 100%;
  padding: 1rem 3rem;
  border-radius: 0.5rem;
  border: 1px solid #A8A8A8;
  background-color: transparent;
}
.signinModal .signinButton:hover {
  outline: 1px solid var(--pf-dark);
}
.signinModal .buttonText {
  color: #212529;
  text-overflow: ellipsis;
  font-family: "DM Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0125rem;
  margin: 0;
}
.signinModal .featureList {
  list-style: none;
  font-size: 1.25rem;
  font-weight: 400;
  padding: 0;
  margin: 0;
}
.signinModal .featureItem {
  padding: 0.7rem 0;
  white-space: nowrap;
}
@media screen and (max-width: 1200px) {
  .signinModal .featureItem {
    white-space: normal;
  }
}
.signinModal .verticalLine {
  height: 100%;
  width: 3px;
  background-color: #3C6CE9;
  border-radius: 50px;
}
@media screen and (max-width: 768px) {
  .signinModal .verticalLine {
    display: none;
  }
}
.signinModal .horizontalLine {
  position: relative;
  color: #A8A8A8;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
}
.signinModal .horizontalLine::before {
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  content: "";
  width: 9rem;
  height: 2px;
  background-color: #A8A8A8;
}
@media screen and (max-width: 1000px) and (min-width: 768px) {
  .signinModal .horizontalLine::before {
    width: 8rem;
  }
}
@media screen and (max-width: 900px) and (min-width: 768px) {
  .signinModal .horizontalLine::before {
    width: 7rem;
  }
}
@media screen and (max-width: 768px) {
  .signinModal .horizontalLine::before {
    width: 25vw;
  }
}
.signinModal .horizontalLine::after {
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  content: "";
  width: 9rem;
  height: 2px;
  background-color: #A8A8A8;
}
@media screen and (max-width: 1000px) and (min-width: 768px) {
  .signinModal .horizontalLine::after {
    width: 8rem;
  }
}
@media screen and (max-width: 900px) and (min-width: 768px) {
  .signinModal .horizontalLine::after {
    width: 7rem;
  }
}
@media screen and (max-width: 768px) {
  .signinModal .horizontalLine::after {
    width: 25vw;
  }
}
.signinModal .boldFeature {
  font-weight: 600;
  font-size: 1.25rem;
}
.signinModal .subscribeInput {
  width: 21.0625rem;
  height: 3.5rem;
  padding: 1rem 3rem 1rem 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid #A8A8A8;
}
@media screen and (max-width: 1200px) {
  .signinModal .subscribeInput {
    width: auto;
  }
}
.signinModal .subscribeButton {
  width: 21.0625rem;
  height: 3.5rem;
  padding: 1rem 3rem;
  border-radius: 0.5rem;
  background-color: #3C6CE9;
  border: 0px;
  color: #fefefe;
}
.signinModal .subscribeButton:hover {
  background-color: var(--pf-light-blue-2);
}
@media screen and (max-width: 1200px) {
  .signinModal .subscribeButton {
    width: auto;
  }
}
.signinModal .magicLinkSentLayout {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.signinModal .magicLinkSentLayout .magicLinkSentText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2.25rem;
}
.signinModal .magicLinkSentLayout .normalText {
  margin-top: 4rem;
  margin-bottom: 1.5rem;
  font-weight: 300;
  font-size: 1.25rem;
}
.signinModal .magicLinkSentLayout .boldText {
  font-weight: 600;
  font-size: 1.25rem;
}
