.comments-component {
  /* Comment Text Area */
}
.comments-component .comments-component h4 {
  font-weight: bold;
}
.comments-component .comment-entry-container {
  display: flex;
  width: 100%;
  height: 5rem;
  margin-bottom: 2rem;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
  padding-right: 20px;
  background-color: white;
  border-radius: 8px;
}
.comments-component .comment-entry-container form {
  display: flex;
  align-items: center;
  width: 100%;
}
.comments-component .comment-entry-container form .comment-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  transform: translateY(15px);
}
.comments-component .comment-entry-container form .submit-button {
  border: none;
  border-radius: 8px;
  background: rgba(60, 108, 233, 0.5);
  color: #FFF;
  display: flex;
  width: 112px;
  height: 33px;
  justify-content: center;
  align-items: center;
}
.comments-component .comment-entry-container form .submit-button:not(:disabled) {
  background-color: var(--pf-light-blue-2);
}
.comments-component .comment-entry-container textarea {
  border-radius: 10px;
}
.comments-component .comment-entry-container .comment-entry-banner {
  position: inline-block;
  width: 1rem;
  height: 100%;
  background-color: var(--pf-blue);
  border-radius: 1rem 0 0 1rem;
  transform: translateX(100%);
}
.comments-component .comment-entry-container #comment-entry {
  padding: 1rem 1rem;
  width: 100%;
  border-radius: 1rem;
  /* box-shadow: 0 4px 4px 0 hsl(0, 0%, 75%);
      border: 1px solid #b8b8b8; */
  border: none;
  resize: none;
  overflow-x: hidden;
}
.comments-component .comment-entry-container #comment-entry:focus-visible {
  outline: none;
}
.comments-component .comments-container {
  border-radius: 1rem;
}
.comments-component .comments-container .comments {
  list-style: none;
  padding: 0;
}
.comments-component .comments-container .comments li hr {
  display: none;
}
@media screen and (max-width: 767px) {
  .comments-component {
    border-radius: 10px;
    padding: 0.5rem;
  }
}
.comments-submit-arrow {
  border: none;
  background-color: white;
}
