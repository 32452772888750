.search-bar-component {
  position: relative;
  height: 50px;
}
.search-bar-component,
.search-bar-component * {
  z-index: 11;
}
.search-bar-component .search-bar-container {
  background: white;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  z-index: 10;
  min-height: 50px;
  position: absolute;
}
.search-bar-component .search-bar-container,
.search-bar-component .search-bar-container .search-results,
.search-bar-component .search-bar-container li,
.search-bar-component .search-bar-container ul,
.search-bar-component .search-bar-container .result a,
.search-bar-component .search-bar-container .see-all-results {
  width: 500px;
}
@media screen and (max-width: 767px) {
  .search-bar-component .search-bar-container,
  .search-bar-component .search-bar-container .search-results,
  .search-bar-component .search-bar-container li,
  .search-bar-component .search-bar-container ul,
  .search-bar-component .search-bar-container .result a,
  .search-bar-component .search-bar-container .see-all-results {
    width: clamp(300px, 100%, 500px);
  }
  @supports not (clamp()) {
    .search-bar-component .search-bar-container,
    .search-bar-component .search-bar-container .search-results,
    .search-bar-component .search-bar-container li,
    .search-bar-component .search-bar-container ul,
    .search-bar-component .search-bar-container .result a,
    .search-bar-component .search-bar-container .see-all-results {
      width: max(300px, 100%);
    }
  }
}
.search-bar-component .search-bar-container span.icon {
  position: absolute;
  opacity: 0.66;
  top: 12px;
  left: 18px;
  cursor: pointer;
}
.search-bar-component .search-bar-container span.icon:hover {
  opacity: 1;
}
.search-bar-component .search-bar-container,
.search-bar-component .search-bar-container input {
  border-radius: 25px;
}
.search-bar-component .search-bar-container input {
  background: transparent;
  width: 100%;
  height: 50px;
  left: 0;
  border: transparent;
  padding: 25px 45px 15px;
  z-index: 12;
}
.search-bar-component .search-bar-container input:focus {
  outline: none;
}
.search-bar-component .search-bar-container input:focus,
.search-bar-component .search-bar-container input.not-empty {
  border-radius: 25px 25px 0 0;
}
.search-bar-component .search-bar-container .label-placeholder,
.search-bar-component .search-bar-container .input-placeholder {
  position: absolute;
  transform-origin: top left;
  opacity: 0.66;
  transition: all 0.2s cubic-bezier(0.2, 0, 0.4, 1);
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.search-bar-component .search-bar-container .label-placeholder {
  opacity: 0;
  transform: translate(2.5rem, 0);
  font-size: 16px;
}
.search-bar-component .search-bar-container input:focus ~ .label-placeholder,
.search-bar-component .search-bar-container input.not-empty ~ .label-placeholder {
  font-size: 14px;
  opacity: 0.66;
}
.search-bar-component .search-bar-container .input-placeholder {
  transform: translate(2.5rem, 0.8rem);
}
.search-bar-component .search-bar-container input:focus ~ .input-placeholder,
.search-bar-component .search-bar-container input.not-empty ~ .input-placeholder {
  transform: translate(2.5rem, 0rem);
  opacity: 0;
}
.search-bar-component .search-bar-container .search-icon {
  z-index: 13;
}
.search-bar-component .search-bar-container .search-results {
  position: relative;
}
.search-bar-component .search-bar-container .search-results .result-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 2.5rem;
  width: 20rem;
  border-bottom: 1px solid hsl(0, 0%, 70%);
}
.search-bar-component .search-bar-container .search-results .career-results-header::after {
  border-bottom: 1px solid hsl(0, 0%, 40%);
}
.search-bar-component .search-bar-container .search-results ul {
  display: flex;
  flex-direction: column;
  border-radius: 0 0 25px 25px;
  padding: 0;
  margin: 0;
  top: 0;
  position: absolute;
  background: white;
}
.search-bar-component .search-bar-container .search-results ul .result-link {
  text-decoration: none;
  color: black;
}
.search-bar-component .search-bar-container .search-results li:not(.see-all-results) {
  height: 30px;
  position: relative;
  padding-top: 3px;
  margin-right: 100px;
  list-style: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.search-bar-component .search-bar-container .search-results li:not(.see-all-results).result-header,
.search-bar-component .search-bar-container .search-results li:not(.see-all-results).no-matches,
.search-bar-component .search-bar-container .search-results li:not(.see-all-results) .result-link,
.search-bar-component .search-bar-container .search-results li:not(.see-all-results) .result-term {
  display: flex;
  width: 100%;
}
.search-bar-component .search-bar-container .search-results li:not(.see-all-results).result-header span:first-of-type,
.search-bar-component .search-bar-container .search-results li:not(.see-all-results).no-matches span:first-of-type,
.search-bar-component .search-bar-container .search-results li:not(.see-all-results) .result-link span:first-of-type,
.search-bar-component .search-bar-container .search-results li:not(.see-all-results) .result-term span:first-of-type {
  padding-left: 40px;
  width: 100%;
}
.search-bar-component .search-bar-container .search-results li:not(.see-all-results) .result-spinner {
  padding-left: 40px;
  width: 100%;
}
.search-bar-component .search-bar-container .search-results li:not(.see-all-results) .match {
  font-weight: 500;
}
.search-bar-component .search-bar-container .search-results li:not(.see-all-results) .match-info {
  position: relative;
  right: 15em;
  top: 0.28em;
  width: 3.19em;
}
.search-bar-component .search-bar-container .search-results li:not(.see-all-results):hover .arrow-right {
  display: inline-block;
}
.search-bar-component .search-bar-container .search-results li:not(.see-all-results) .arrow-right {
  display: none;
  margin-right: 35px;
  width: 30px;
  height: 18px;
  align-self: center;
  flex-shrink: 0;
}
.search-bar-component .search-bar-container .search-results li:not(.see-all-results) .percent-match-container {
  background: white;
  position: relative;
  right: 8px;
  float: right;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 10px;
  border: 1px solid hsl(0, 0%, 85%);
  border-radius: 20px;
  height: 50%;
}
.search-bar-component .search-bar-container .search-results li:not(.see-all-results).no-matches {
  border-radius: 0 0 25px 25px;
  font-style: italic;
}
.search-bar-component .search-bar-container .search-results li:not(.see-all-results).no-matches .feedback-link {
  font-style: italic;
  color: var(--pf-light-blue);
  float: right;
  margin-right: 1rem;
}
.search-bar-component .search-bar-container .search-results li:not(.see-all-results).no-matches .feedback-link:hover {
  color: var(--pf-blue);
}
.search-bar-component .search-bar-container .search-results .initial-suggestion {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.search-bar-component .search-bar-container .search-results .initial-suggestion .arrow-right {
  margin-top: 0px;
  left: 90%;
  position: absolute;
}
.search-bar-component .search-bar-container .search-results .suggestion-term-label {
  display: inline-block;
  margin-left: auto;
  margin-right: 140px;
  vertical-align: middle;
  color: #444444;
  font-size: 16px;
}
.search-bar-component .search-bar-container .search-results .results-tooltip {
  user-select: none;
}
.search-bar-component .search-bar-container .search-results .result .match {
  font-weight: 500;
  text-decoration: none;
  color: #000000;
}
.search-bar-component .search-bar-container .search-results .result:hover,
.search-bar-component .search-bar-container .search-results .result.selected {
  cursor: pointer;
  background: hsl(0, 0%, 90%);
}
.search-bar-component .search-bar-container li.see-all-results {
  background: #e6e0ff;
  height: 40px;
  color: #7b61ff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.search-bar-component .search-bar-container li.see-all-results span {
  font-size: 20px;
  font-weight: 500;
}
.search-bar-component .search-bar-container li.see-all-results,
.search-bar-component .search-bar-container li.see-all-results::after {
  border-radius: 0 0 25px 25px;
  -webkit-transition: opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.search-bar-component .search-bar-container li.see-all-results::after {
  content: '';
  position: absolute;
  box-shadow: 0 0 5px 1px #7b61cc;
  width: 100%;
  height: 40px;
  opacity: 0;
}
.search-bar-component .search-bar-container li.see-all-results:hover::after {
  opacity: 1;
}
.search-bar-component .search-bar-container .x-icon {
  display: none;
  background: lightgray;
  color: white;
  position: absolute;
  top: 1rem;
  right: 5%;
  padding: 0.2rem;
  transform: scale(0.75);
  border-radius: 50rem;
  border: transparent;
  cursor: pointer;
  z-index: 13;
}
.search-bar-component .search-bar-container input.not-empty ~ .x-icon {
  display: block;
}
.search-bar-component .search-bar-container .border-bottom {
  border-radius: 0 0 25px 25px;
}
.search-bar-component .search-bar-suggestions-container {
  z-index: 0;
  margin-left: 5%;
  margin-top: 5px;
}
.search-bar-component .keyword-buttons-container {
  display: flex;
  flex-direction: row;
}
.search-bar-component .keyword-buttons-container .keyword-suggestion-button {
  background-color: var(--pf-blue);
  color: white;
  border: 1px solid white;
  border-radius: 20px;
  margin: 5px;
  padding: 7px 10px 7px 10px;
  font-weight: bolder;
  cursor: pointer;
  font-size: smaller;
  white-space: nowrap;
}
.search-bar-component .keyword-buttons-container .selected {
  outline: 1px solid var(--pf-blue);
  border: 2px solid white;
}
::placeholder {
  opacity: 0.66;
}
::-ms-input-placeholder,
::-webkit-input-placeholder,
::-moz-placeholder {
  opacity: 0.66;
}
@media screen and (max-width: 767px) {
  .search-bar-component .search-results .result-header::after {
    width: 16rem;
  }
  .search-bar-component .search-bar-container .search-results .suggestion-term-label {
    margin-right: 50px;
  }
}
@media screen and (max-width: 480px) {
  .search-bar-component .feedback-link {
    font-size: 12px;
    padding-top: 3px;
  }
}
