/* Search box css */
/* .careers-component .search-box {
  top: 10px;
  left: 0px;
  right: 0px;
  margin: 10px;
  z-index: 0;
} */
/* Details css */
.careers-component {
  color: var(--pf-dark);
  margin: 10px auto 5% auto;
  justify-content: center;
}
.careers-component li:last-child {
  margin-bottom: 0;
}
.careers-component ul {
  padding: 0;
  margin: 0;
}
.careers-component .heatmap-indicator {
  --bkg-light: 50%;
  background-color: hsl(0 75% var(--bkg-light));
  --color-light: 50%;
  color: hsl(240 0% var(--color-light));
  text-align: center;
  font-weight: bold;
  --size: 1.5rem;
  flex: 0 0 var(--size);
  height: var(--size);
  border-radius: 50rem;
  border: 1px solid #000000;
  margin-right: 1rem;
  cursor: default;
}
.careers-component .careers-header {
  margin-bottom: 1rem;
}
.careers-component .heatmap-legend {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}
.careers-component .heatmap-legend .heatmap-gradient {
  width: 16rem;
  height: 2rem;
  background-image: linear-gradient(90deg, hsl(0, 75%, 90%), hsl(0, 75%, 40%));
  border-radius: 50rem;
}
.careers-component .careers-columns {
  display: flex;
  flex-direction: row;
  margin: 0 0 5% 0px;
  width: 100%;
}
.careers-component .careers-columns strong {
  font-size: larger;
}
.careers-component .careers-columns .column {
  display: flex;
  flex-direction: column;
  padding: 5px;
  width: 100%;
}
.careers-component .careers-columns .column:not(:last-child) {
  border-right: 1px solid lightgray;
  top: 25%;
  bottom: 25%;
}
.careers-component .careers-columns .column .category-container {
  transition: box-shadow 0.3s;
  padding: 20px;
  border-radius: 10px;
  margin: 5px 10px 5px 10px;
  text-decoration: none;
  color: var(--pf-dark);
}
.careers-component .careers-columns .column .category-container:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.4);
  cursor: pointer;
}
.careers-component .careers-columns .column .category-container:hover strong {
  color: var(--pf-brown);
}
.careers-component .careers-columns .column .category-container.popular {
  background-image: linear-gradient(45deg, hsl(0, 0%, 95%), var(--pf-blue));
}
.careers-component .careers-columns .column .category-container li {
  list-style: none;
  margin: 5px 5px 5px 0;
  padding: 0;
  display: flex;
  align-items: center;
}
.careers-component .careers-columns .column .category-container li .career-link {
  text-decoration: none;
  color: var(--pf-dark);
  font-size: 1.1rem;
  white-space: nowrap;
}
.careers-component .careers-columns .column .category-container a:hover {
  color: var(--pf-brown);
}
.careers-component .category-details-container {
  margin: 1rem 0 5% 12px;
}
.careers-component .category-details-container .careers-details-header {
  font-size: 1.8rem;
  color: var(--pf-brown);
}
.careers-component .category-details-container li {
  padding-top: 5px;
}
.careers-component .category-details-container .category-details-items {
  list-style: none;
  margin: 5px 5px 5px 0;
  padding: 0;
}
.careers-component .category-details-container .category-details-items li {
  display: flex;
  align-items: center;
}
.careers-component .category-details-container .category-details-items a {
  text-decoration: none;
  color: var(--pf-dark);
  font-size: 1.1rem;
  font-size: 1.3rem;
}
.careers-component .category-details-container .category-details-items a:hover {
  cursor: pointer;
  color: var(--pf-brown);
}
.careers-component .category-details-container .back-arrow {
  float: left;
  margin-left: -40px;
  cursor: pointer;
  font-size: 25px;
  color: var(--pf-blue);
  vertical-align: middle;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .careers-component .careers-columns {
    margin: 0;
  }
  .careers-component .category-details-container {
    margin-left: 50px;
  }
  .careers-component .column {
    width: 100%;
  }
}
