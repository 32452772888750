.about-component {
  display: flex;
  flex-direction: column;
  font-size: 20px;
}
.about-component > div {
  padding: 5%;
}
.about-component .mission-container {
  background-image: url('../../images/aboutImg/blueLayer1.png');
  background-size: 101% 100%;
  background-repeat: no-repeat;
}
.about-component .mission-container .mission-bg {
  z-index: 1;
  position: absolute;
  width: 100%;
  margin-bottom: 100px;
}
.about-component .mission-container h1 {
  color: #7BFFA8;
  text-align: left;
}
.about-component .mission-container img {
  max-width: 50%;
}
.about-component .mission-content {
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
  padding: 5%;
  color: white;
  font-size: 26px;
}
.about-component .story-container {
  color: #3C3C3C;
  margin-left: 10%;
  margin-right: 10%;
  text-align: left;
}
.about-component .story-container h1 {
  color: #B96E4E;
  text-align: center;
}
.about-component .team-container {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('../../images/aboutImg/greyLayer1.png');
  background-size: 101% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.about-component .team-container h1 {
  margin-bottom: 2rem;
}
.about-component .team-container .team-row {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 1rem;
}
.about-component .team-container .product-and-past-team {
  display: flex;
  flex-direction: row;
  gap: 4rem;
}
.about-component .team-container .product-and-past-team .product-team {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.about-component .team-container h1 {
  color: #7BFFA8;
  text-align: center;
}
.about-component .team-container i {
  color: #7BFFA8;
}
.about-component .team-container .teammate {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 2rem;
  width: 200px;
}
.about-component .team-container .teammate strong {
  padding-top: 10px;
}
.about-component .team-container .teammate img {
  width: 200px;
  height: 200px;
}
.about-component .team-container .interns > div,
.about-component .team-container .past-contributors > div {
  margin-top: 2rem;
}
.about-component .team-container .intern {
  margin-bottom: 1rem;
}
.about-component .feedback-container {
  color: #3C3C3C;
  text-align: center;
}
.about-component .feedback-container button {
  background-color: #B96E4E;
  color: white;
  border: #804D37 2px;
  border-radius: 5px;
  padding: 15px;
}
.about-component .feedback-container button:hover {
  background-color: hsl(18, 43%, 57%);
}
.about-component .contact-container {
  background-color: #002175;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-component .contact-container a {
  color: white;
}
.about-component .contact-container a:hover {
  color: lightblue;
}
.about-component .contact-container .contact-text {
  text-align: left;
  margin-right: 4rem;
}
.about-component .contact-container .contact-text h1 {
  color: #7BFFA8;
  margin-bottom: 1rem;
}
@media screen and (max-width: 767px) {
  .about-component .mission-container {
    align-items: center;
  }
  .about-component .mission-container img {
    display: none;
  }
  .about-component .team-container {
    background-size: 300% 100%;
  }
  .about-component .team-container img {
    width: 100%;
  }
  .about-component .team-container .product-team {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-component .team-container .product-team .team-row {
    margin-bottom: 0;
  }
  .about-component .team-container .dev-team {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-component .team-container .product-and-past-team {
    flex-direction: column;
    align-items: center;
  }
  .about-component .team-container .interns,
  .about-component .team-container .past-contributors {
    margin-left: 0;
    text-align: center;
  }
  .about-component .team-container .team-row {
    justify-content: center;
  }
  .about-component .contact-container {
    flex-direction: column;
  }
  .about-component .contact-container .contact-text {
    margin-right: 0;
    margin-bottom: 4rem;
  }
}
