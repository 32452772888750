.chat-response-container {
  display: flex;
  flex-direction: row;
}
.chat-response-container .chat-response-content {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
@media screen and (max-width: 768px) {
  .chat-response-container .chat-response-content {
    margin-left: 0;
  }
}
.chat-response-container .chat-response-content .chat-response {
  display: inline-block;
  margin: 5px;
  padding: 25px;
  width: 75%;
  border: 1px solid #6E6E6E;
  border-radius: 10px;
  border-top-left-radius: 0;
  background-color: white;
  filter: drop-shadow(0px 5px 3px #C8C8C8);
  font-size: 16px;
  color: #212529;
  line-height: 2rem;
  white-space: pre-line;
}
@media screen and (max-width: 768px) {
  .chat-response-container .chat-response-content .chat-response {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
.chat-response-container .chat-response-content .chat-response > strong {
  font-size: 18px;
}
.chat-response-container .chat-response-content .chat-response .story-preview-container {
  border-top: 2px solid #727272;
  margin-top: 25px;
  padding-top: 25px;
}
.chat-response-container .chat-response-content .chat-response .story-preview-container > strong {
  font-size: 18px;
}
.chat-response-container .chat-response-content .chat-response .story-preview-content {
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  gap: 22px;
}
@media screen and (max-width: 768px) {
  .chat-response-container .chat-response-content .chat-response .story-preview-content {
    flex-direction: column;
  }
}
.chat-response-container .chat-response-content .chat-response .story-preview {
  padding: 10px;
  border: 1px solid #727272;
  border-radius: 10px;
}
