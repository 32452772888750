.navigation-component {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 1em;
  z-index: 101;
  position: sticky;
  top: 0;
}
.navigation-component + :not(footer) {
  flex: 1;
}
.navigation-component .navigation-container {
  display: flex;
  align-items: center;
  align-self: center;
  background-color: var(--pf-light-1);
  width: 100%;
  border-radius: 15px;
  box-shadow: 1px 2px 24px 0 hsla(210, 11%, 15%, 0.25);
  padding: 8px;
}
.navigation-component .navigation-container .logo {
  width: 214px;
  height: auto;
  margin: 0.5rem 1rem;
  position: inline;
}
.navigation-component .navigation-container #menu-toggle {
  display: none;
}
.navigation-component .navigation-container .menu-button-container {
  display: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  background: var(--pf-blue);
  border-radius: 50rem;
}
.navigation-component .navigation-container .menu-button,
.navigation-component .navigation-container .menu-button::before,
.navigation-component .navigation-container .menu-button::after {
  display: block;
  background-color: #fdfdfd;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}
.navigation-component .navigation-container .menu-button::before {
  content: '';
  margin-top: -8px;
}
.navigation-component .navigation-container .menu-button::after {
  content: '';
  margin-top: 8px;
}
.navigation-component .navigation-container .menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.navigation-component .navigation-container .menu li.nav-left {
  display: flex;
  align-items: center;
}
.navigation-component .navigation-container .menu li.nav-left + .nav-right {
  margin-left: auto;
  margin-right: 1.5rem;
}
.navigation-component .navigation-container .menu li.nav-left a {
  color: #3c3c3c;
  margin-left: 4rem;
}
.navigation-component .navigation-container .menu li.nav-right a {
  color: var(--pf-brown);
}
.navigation-component .navigation-container .menu .nav-page-link::after {
  content: '';
  position: absolute;
  bottom: 36px;
  width: 50px;
  height: 3px;
  background-color: var(--pf-blue);
  opacity: 0;
  margin-left: 0;
  border-radius: 50rem;
  cursor: pointer;
}
.navigation-component .navigation-container .menu .nav-page-link.selected,
.navigation-component .navigation-container .menu .nav-page-link:hover {
  text-decoration: underline 3px solid #3C6CE9;
  font-weight: bold;
}
.navigation-component .navigation-container .menu .nav-link {
  font-size: 18px;
  text-decoration: none;
  display: flex;
}
.navigation-component .navigation-container .menu .nav-border-right {
  position: relative;
}
.navigation-component .navigation-container .menu .nav-border-right::after {
  content: '';
  position: absolute;
  right: 0;
  height: 50%;
  top: 25%;
  border-right: 2px solid lightgray;
}
.navigation-component .navigation-container .signin-button {
  display: flex;
  align-items: center;
  padding: 16px 48px;
  border-radius: 8px;
  border: none;
  background-color: #3C6CE9;
  color: white;
}
.navigation-component .navigation-container .signin-button:hover {
  background-color: var(--pf-light-blue-2);
}
.navigation-component .navigation-container .user-dropdown {
  position: relative;
}
@media screen and (max-width: 767px) {
  .navigation-component .navigation-container .user-dropdown {
    display: flex;
    justify-content: center;
    position: initial;
  }
}
.navigation-component .navigation-container .user-dropdown .user-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  user-select: none;
}
.navigation-component .navigation-container .user-dropdown .user-info:hover {
  cursor: pointer;
}
.navigation-component .navigation-container .user-dropdown .user-menu {
  position: absolute;
  border-radius: 8px;
  background-color: white;
  width: 256px;
  padding: 8px 0 8px;
  right: -32px;
  left: auto;
  box-shadow: 0 1px 4px 0 hsl(0, 0%, 0%);
}
@media screen and (max-width: 767px) {
  .navigation-component .navigation-container .user-dropdown .user-menu {
    transform: translateY(100%);
    right: auto;
    width: auto;
  }
}
.navigation-component .navigation-container .user-dropdown .user-menu li {
  display: flex;
  align-items: center;
  background-color: white;
  list-style: none;
  width: 100%;
  padding: 8px 16px;
  line-height: 20.83px;
  font-weight: 500;
  border-radius: 8px;
}
@media screen and (max-width: 767px) {
  .navigation-component .navigation-container .user-dropdown .user-menu li {
    padding: 0 8px;
  }
}
.navigation-component .navigation-container .user-dropdown .user-menu li:hover {
  text-decoration: underline 2px;
  text-decoration-color: hsl(223, 80%, 57%);
  cursor: pointer;
}
.navigation-component .navigation-container .user-dropdown .user-menu li span {
  margin-left: 8px;
}
@media (max-width: 992px) {
  .navigation-component .navigation-container {
    --mobile-opacity: 0;
    background-color: var(--mobile-opacity);
  }
  .navigation-component .navigation-container .logo {
    width: 160px;
    margin-left: 0;
  }
  .navigation-component .navigation-container #menu-toggle ~ .menu li,
  .navigation-component .navigation-container #menu-toggle ~ .menu li.nav-left a {
    overflow: hidden;
    margin: 0;
    height: 0;
    border: 0;
    transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
  }
  .navigation-component .navigation-container #menu-toggle:checked + .menu-button-container .menu-button {
    background: rgba(255, 255, 255, 0);
  }
  .navigation-component .navigation-container #menu-toggle:checked + .menu-button-container .menu-button::before {
    margin-top: 0px;
    transform: rotate(405deg);
  }
  .navigation-component .navigation-container #menu-toggle:checked + .menu-button-container .menu-button::after {
    margin-top: 0px;
    transform: rotate(-405deg);
  }
  .navigation-component .navigation-container #menu-toggle:checked ~ .menu {
    /* Mobile Menu on Open */
    border: 0.5px solid var(--pf-blue);
  }
  .navigation-component .navigation-container #menu-toggle:checked ~ .menu li .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .navigation-component .navigation-container #menu-toggle:checked ~ .menu li:not(.nav-left),
  .navigation-component .navigation-container #menu-toggle:checked ~ .menu li.nav-left a {
    border: 0.5px solid var(--pf-blue);
    height: 3rem;
    width: 100%;
  }
  .navigation-component .navigation-container #menu-toggle:checked ~ .menu li.nav-left {
    flex-direction: column;
    flex: 0 0 100%;
    padding: 0;
    height: auto;
  }
  .navigation-component .navigation-container #menu-toggle:checked ~ .menu li.nav-left a {
    display: flex;
    justify-content: center;
  }
  .navigation-component .navigation-container .menu-button-container {
    display: flex;
    margin-left: auto;
    padding: 1.25rem;
  }
  .navigation-component .navigation-container .menu-button-container .menu-button,
  .navigation-component .navigation-container .menu-button-container .menu-button::before,
  .navigation-component .navigation-container .menu-button-container .menu-button::after {
    width: 24px;
    height: 3px;
  }
  .navigation-component .navigation-container .menu {
    z-index: 1001;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 75px;
    width: 100%;
    flex-direction: column;
  }
  .navigation-component .navigation-container .menu > li {
    display: flex;
    justify-content: center;
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: #fdfdfd;
  }
  .navigation-component .navigation-container .menu > li.nav-left {
    flex: initial;
  }
  .navigation-component .navigation-container .menu > li:not(:last-child) {
    border-bottom: 1px solid #444;
  }
  .navigation-component .navigation-container .menu > li a {
    padding: 0;
  }
  .navigation-component .navigation-container .menu .nav-page-link::after {
    height: 0;
  }
  .navigation-component .navigation-container .nav-border-right::after {
    display: none;
  }
}
