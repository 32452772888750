.notfound {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 55vh;
  gap: 2rem;
}

.text {
  font-size: 2rem;
  color: var(--pf-blue);
}

.backButton {
  padding: 0.5rem 1rem;
  background: var(--pf-blue);
  border: transparent;
  border-radius: 50rem;
  color: white;
}

.backButton:hover {
  color: white;
}