.share-container {
  display: flex;
  position: relative;
  justify-content: flex-end;
  color: #527EF0;
  border: none;
  border-radius: 10px;
  right: -10px;
}
.share-container button {
  color: #527EF0;
  background: none;
  border: none;
}
.share-modal-container {
  display: relative;
  border: none;
  background-color: white;
  width: auto;
  border-radius: 10px;
  z-index: 900;
  margin-bottom: -120px;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
}
.share-modal-container .share-modal-entry {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 40px;
  gap: 10px;
  cursor: pointer;
}
.share-modal-container .share-modal-entry:hover {
  background-color: #f9f9f9;
  border-radius: 10px;
}
.share-modal-container .share-modal-entry img {
  height: 20px;
  width: 20px;
}
.share-modal-overlay {
  position: fixed;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  z-index: 105;
  cursor: default;
}
.box {
  width: 300px;
  margin: 50px auto;
  background: #00bfb6;
  padding: 20px;
  text-align: center;
  font-weight: 900;
  color: #fff;
  font-family: arial;
  position: relative;
}
.copy {
  color: white;
  height: 50px;
  width: 300px;
  background-color: #527EF0;
  position: fixed;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 5%;
  left: 50%;
  padding: 10px;
  transform: translateX(-50%);
  z-index: 1000;
  opacity: 0;
  /* Start with opacity set to 0 */
  transition: opacity 0.25s ease-out;
  /* Add a transition for the opacity property */
  visibility: hidden;
}
.copy.show {
  opacity: 1;
  visibility: visible;
}
