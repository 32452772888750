.ai-chat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 10;
}
.ai-chat .chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 150px;
  gap: 2rem;
}
@media screen and (max-width: 768px) {
  .ai-chat .chat-container {
    margin-top: 50px;
  }
}
.ai-chat .chat-container .chat-header {
  font-size: 60px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .ai-chat .chat-container .chat-header {
    font-size: 44px;
  }
}
.ai-chat .chat-container .chat-subtext {
  font-size: 24px;
  width: 90%;
}
.ai-chat .chat-container .chat-box {
  margin-top: 4rem;
}
.ai-chat .chat-input-container .chatSuggestion-container {
  margin-top: 1.5rem;
}
.ai-chat > :first-child:not(.chat-container) {
  margin-top: 2rem;
}
.ai-chat .beta-message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 50px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 1px 2px 10px 0px rgba(33, 37, 41, 0.2);
}
.ai-chat .message-content {
  flex: 1;
  color: #212529;
  font-size: 20px;
  letter-spacing: 0.03px;
  text-align: center;
  width: 853px;
  min-height: 26px;
}
.ai-chat .message-content .learn-more {
  color: #3C6CE9;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.03px;
  text-decoration-line: underline;
}
.ai-chat .close-button {
  cursor: pointer;
  margin-right: 13px;
}
.ai-chat .blue-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 0 1.5rem;
  width: 100%;
  height: 47.5rem;
  background-color: #dce6ff;
  border-radius: 0.625rem;
}
.ai-chat .blue-area .scroll-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  border-radius: 8px;
  padding: 0 1.5rem;
  width: 100%;
  height: 100%;
}
.ai-chat .blue-area .scroll-container .chat-response-container {
  width: auto;
}
.ai-chat .blue-area .scroll-container .chat-bubble-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 64px;
  height: 64px;
  border-radius: 40px;
  flex-shrink: 0;
}
.ai-chat .blue-area .scroll-container .chat-bubble-container .chat-bubble-icon {
  width: 64px;
  height: 64px;
  border-radius: 40px;
}
.ai-chat .blue-area .scroll-container .chat-bubble-container .pf-avatar {
  width: 48px;
  height: 48px;
  border-radius: 0;
}
@media screen and (max-width: 768px) {
  .ai-chat .blue-area .scroll-container .chat-bubble-container {
    display: none;
  }
}
.ai-chat .blue-area .scroll-container .chat-response-title {
  margin-left: 5px;
  font-size: 20px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .ai-chat .blue-area .scroll-container .chat-response-title {
    margin: 0;
  }
}
.ai-chat .blue-area .scroll-container .reply-utils {
  display: flex;
  max-width: 75%;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  height: 4.12rem;
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .ai-chat .blue-area .scroll-container .reply-utils {
    max-width: 100%;
  }
}
.ai-chat .blue-area .scroll-container .reply-util {
  display: grid;
  place-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border: 1px solid black;
  border-radius: 1.25rem;
  border: 1px solid #C6C9CE;
  background: #E6E9F0;
}
.ai-chat .blue-area .scroll-container .loading-bubble-container {
  display: flex;
  flex-direction: row;
}
.ai-chat .blue-area .scroll-container .loading-bubble-container .loading-bubble-content {
  display: flex;
  flex-direction: column;
  margin-left: 5px;
}
.ai-chat .blue-area .scroll-container .loading-bubble-container .loading-bubble-content .loading-bubble {
  width: 10.9375rem;
  height: 4.25rem;
  border-radius: 0rem 0.625rem 0.625rem 0.625rem;
  border: 1px solid #6E6E6E;
  background-color: #fefefe;
  box-shadow: 1px 2px 11px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-left: 5px;
  margin-bottom: 15px;
}
.ai-chat .blue-area .scroll-container .prompt-bubble-container {
  display: flex;
  justify-content: flex-end;
}
.ai-chat .blue-area .scroll-container .prompt-bubble {
  color: #fefefe;
  padding: 1.625rem;
  border-radius: 0.5rem 0.0625rem 0.5rem 0.5rem;
  background: #3C6CE9;
  box-shadow: -1px 2px 10px 0px rgba(0, 0, 0, 0.25);
  width: fit-content;
  max-width: 100%;
  overflow-wrap: break-word;
}
.ai-chat .blue-area .scroll-container .dot {
  height: 1rem;
  width: 1rem;
  background-color: black;
  border-radius: 100%;
}
.ai-chat .blue-area .scroll-container .dot1 {
  animation: load 2s infinite;
}
.ai-chat .blue-area .scroll-container .dot2 {
  animation: load 2s 0.5s infinite;
}
.ai-chat .blue-area .scroll-container .dot3 {
  animation: load 2s 1s infinite;
}
@keyframes load {
  0% {
    background-color: black;
  }
  50% {
    background-color: white;
  }
  100% {
    background-color: black;
  }
}
