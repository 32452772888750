.chat-story-preview {
  width: 30%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #6E6E6E;
  background: #FFF;
  border-radius: 24px;
}
@media screen and (max-width: 768px) {
  .chat-story-preview {
    width: 100%;
  }
}
.chat-story-preview > a {
  text-decoration: none;
  color: var(--pf-dark-2);
  padding: 12px;
}
.chat-story-preview:hover {
  background-color: #f9f9f9;
}
.chat-story-preview .preview-header {
  display: flex;
}
.chat-story-preview .preview-header .avatar {
  border: 1px solid #DCDCDC;
  border-radius: 50%;
  font-size: 30px;
  font-weight: 500;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
}
.chat-story-preview .preview-header .poster-name {
  padding-top: 10px;
  padding-left: 10px;
  color: #212529;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.016px;
}
.chat-story-preview .preview-body {
  padding: 0.62rem 0px 1.38rem 0px;
  height: 50%;
  text-align: left;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.056px;
}
.chat-story-preview .preview-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chat-story-preview .preview-footer .reaction-button {
  padding: 0px 8px;
}
.chat-story-preview .preview-footer .reaction-button:hover {
  outline: none;
}
.chat-story-preview .preview-footer .comment-count {
  color: gray;
  font-size: 0.75rem;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
}
