.resource-section {
  padding-top: 25px;
}
.resource-section .resource-section-container {
  display: flex;
  flex-direction: row;
  gap: 3%;
  margin-top: 10px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .resource-section .resource-section-container {
    flex-direction: column;
    gap: 25px;
  }
}
.resource-section > strong {
  font-size: 18px;
}
