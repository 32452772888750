.advanced-search-component .result {
  list-style: none;
  margin: 0;
  padding: 0;
}
.advanced-search-component .advanced-search-container .advanced-search-bar {
  width: 300px;
  height: 40px;
  margin-top: 20px;
  border-radius: 25px;
  border: transparent;
  padding: 0 20px;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.1);
}
.advanced-search-component .advanced-search-container .advanced-search-bar:focus {
  outline: none;
}
.advanced-search-component .advanced-search-container .suggestions {
  margin-top: 30px;
}
.advanced-search-component .advanced-search-container .suggestions ul {
  padding-left: 0;
}
.advanced-search-component .advanced-search-container .suggestions ul .result-header {
  list-style: none;
  margin: 0;
  padding: 0;
}
.advanced-search-component .advanced-search-container .suggestions ul .result {
  width: 290px;
}
.advanced-search-component .advanced-search-container .suggestions ul .result:hover {
  background: hsl(0, 0%, 90%);
  cursor: pointer;
}
.advanced-search-component .advanced-search-results .display-advanced-search-results {
  width: min(100%, 32rem);
}
.advanced-search-component .advanced-search-results .display-advanced-search-results .percent-header {
  float: right;
}
.advanced-search-component .advanced-search-results .display-advanced-search-results .result:hover {
  background: hsl(0, 0%, 90%);
  cursor: pointer;
}
.advanced-search-component .advanced-search-results .display-advanced-search-results .result .percent-match-container {
  background: white;
  margin-right: 16px;
  float: right;
  font-size: 14px;
  font-weight: 500;
  padding: 0px 10px;
  border: 1px solid hsl(0, 0%, 85%);
  border-radius: 20px;
}
.advanced-search-component .list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}
