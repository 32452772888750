@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf) format('truetype');
}
.landing-component {
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-repeat: no-repeat;
  background-position: 10px -150px;
  position: relative;
}
.landing-component .arrow-up {
  display: none;
}
.landing-component .landing-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  gap: 200px;
  padding: 0 196px;
  max-width: 1728px;
}
@media screen and (max-width: 768px) {
  .landing-component .landing-container {
    gap: 100px;
  }
}
@media screen and (max-width: 1300px) {
  .landing-component .landing-container {
    padding: 0 10px;
  }
}
.landing-component .landing-container .landing-colorText {
  color: var(--pf-blue);
}
.landing-component .landing-container .landing-img {
  background: transparent;
  position: relative;
  z-index: 20;
}
@media screen and (max-width: 1450px) {
  .landing-component .landing-container .landing-img {
    width: 100%;
    height: auto;
    aspect-ratio: auto;
  }
}
.landing-component .landing-container .landing-button {
  padding: 16px 48px;
  color: #FFF;
  background-color: #3C6CE9;
  border-radius: 8px;
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
}
.landing-component .landing-container .landing-button:hover {
  background-color: var(--pf-light-blue-2);
}
.landing-component .landing-container .landing-sign-in-link {
  cursor: pointer;
}
.landing-component .landing-container .landing-sign-in-suggestion {
  z-index: 30;
}
.landing-component .landing-container .landing-instant-answers-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 116px;
  z-index: 50;
}
@media screen and (max-width: 768px) {
  .landing-component .landing-container .landing-instant-answers-container {
    flex-direction: column;
    gap: 0;
  }
}
.landing-component .landing-container .landing-instant-answers-container .landing-instant-answers-question {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 26px;
  margin-left: -100px;
  margin-top: -90px;
  position: absolute;
  z-index: 21;
  width: 377px;
  height: 115px;
  font-size: 16px;
  border: 2px solid #3C6CE9;
  border-radius: 10px;
  background: #FDFDFD;
  box-shadow: -1px 2px 10px 0px rgba(0, 0, 0, 0.25);
}
.landing-component .landing-container .landing-instant-answers-container .landing-instant-answers-question span {
  background: linear-gradient(90deg, #3C6CE9 0%, #0500FF 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 768px) {
  .landing-component .landing-container .landing-instant-answers-container .landing-instant-answers-question {
    font-size: 0.7rem;
    margin-left: 20px;
    padding: 10px;
    width: 250px;
    height: auto;
    margin-top: -60px;
  }
}
.landing-component .landing-container .landing-instant-answers-container .landing-instant-answers-left {
  margin-bottom: 185px;
}
@media screen and (max-width: 768px) {
  .landing-component .landing-container .landing-instant-answers-container .landing-instant-answers-left {
    margin-bottom: 100px;
  }
}
.landing-component .landing-container .landing-instant-answers-container .landing-instant-answers-left p {
  margin-top: 10px;
  margin-bottom: 40px;
}
.landing-component .landing-container .landing-instant-answers-container .landing-instant-answers-left .landing-try-now {
  cursor: pointer;
}
.landing-component .landing-container .landing-instant-answers-container .landing-instant-answers-right {
  max-width: 758px;
  max-height: 681px;
}
.landing-component .landing-container .landing-featured-stories-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.landing-component .landing-container .landing-featured-stories-container .landing-featured-stories-description {
  width: 70%;
  text-align: center;
}
@media screen and (max-width: 550px) {
  .landing-component .landing-container .landing-featured-stories-container .landing-featured-stories-description {
    margin: 20px 40px;
    margin-left: 0;
    text-align: left;
    width: auto;
  }
}
.landing-component .landing-container .landing-featured-stories-container .landing-featured-stories-cards {
  display: flex;
  flex-direction: column;
  gap: 79px;
  margin: 79px 0;
}
.landing-component .landing-container .landing-featured-stories-container .landing-featured-stories-cards .landing-featured-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .landing-component .landing-container .landing-featured-stories-container .landing-featured-stories-cards .landing-featured-row {
    flex-direction: column;
    gap: 40px;
  }
}
.landing-component .landing-container .landing-featured-stories-container .landing-featured-stories-cards .landing-featured-row .landing-featured-card {
  text-decoration: none;
  color: black;
  max-width: 30%;
}
@media screen and (max-width: 768px) {
  .landing-component .landing-container .landing-featured-stories-container .landing-featured-stories-cards .landing-featured-row .landing-featured-card {
    max-width: 375px;
  }
}
.landing-component .landing-container .landing-featured-stories-container .landing-featured-stories-cards .landing-featured-row .landing-featured-card .landing-featured-splash-img {
  width: 100%;
  max-height: 230px;
  border-radius: 24px 24px 0 0;
  background: black;
}
.landing-component .landing-container .landing-featured-stories-container .landing-featured-stories-cards .landing-featured-row .landing-featured-card .landing-featured-content-container {
  margin-top: -45px;
  padding: 23px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 195px;
  position: relative;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 23;
}
.landing-component .landing-container .landing-featured-stories-container .landing-featured-stories-cards .landing-featured-row .landing-featured-card .landing-featured-content-container h5:hover {
  text-decoration: underline;
  color: #3C6CE9;
}
@media screen and (max-width: 1000px) and (min-width: 768px) {
  .landing-component .landing-container .landing-featured-stories-container .landing-featured-stories-cards .landing-featured-row .landing-featured-card .landing-featured-content-container h5 {
    font-size: 1rem;
  }
}
.landing-component .landing-container .landing-featured-stories-container .landing-featured-stories-cards .landing-featured-row .landing-featured-card .landing-featured-content-container .landing-featured-profile-img {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}
.landing-component .landing-container .landing-featured-stories-container .landing-featured-stories-cards .landing-featured-row .landing-featured-card .landing-featured-content-container:hover {
  background: rgba(255, 255, 255, 0.95);
}
.landing-component .landing-container .landing-featured-stories-container .landing-featured-stories-cards .landing-featured-row .landing-featured-card:hover {
  cursor: pointer;
  text-decoration: underline;
  color: #3C6CE9;
}
.landing-component .landing-container .landing-join-experts-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 138px;
}
@media screen and (max-width: 768px) {
  .landing-component .landing-container .landing-join-experts-container {
    flex-direction: column;
    gap: 20px;
  }
}
.landing-component .landing-container .landing-join-experts-container .landing-join-experts-left .join-experts-img {
  max-width: 733.826px;
  max-height: 601px;
}
.landing-component .landing-container .landing-join-experts-container .landing-join-experts-right p {
  margin-top: 20px;
  margin-bottom: 44px;
}
.landing-component .landing-container .landing-try-today-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 106px;
}
@media screen and (max-width: 768px) {
  .landing-component .landing-container .landing-try-today-container {
    flex-direction: column-reverse;
    gap: 20px;
  }
}
.landing-component .landing-container .landing-try-today-container .landing-try-today-left p {
  margin-top: 10px;
  margin-bottom: 40px;
}
.landing-component .landing-container .landing-try-today-container .landing-try-today-right .try-today-img {
  max-width: 768px;
  max-height: 526px;
}
.landing-component .landing-container .subscribe-container {
  width: 100%;
  max-width: 600px;
}
.landing-component .landing-container .subscribe-container #subscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -1px;
}
.landing-component .landing-container .subscribe-container #subscribe h1 {
  font-size: 40px;
}
.landing-component .landing-container .subscribe-container #subscribe .subscribe-form {
  margin-bottom: 42px;
  width: 100%;
}
.landing-component .landing-container .subscribe-container #subscribe .subscribe-form .subscribe-feedback {
  align-self: start;
  margin-left: 23px;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}
.landing-component .landing-container .subscribe-container #subscribe .subscribe-form .subscribe-feedback.show {
  opacity: 1;
}
.landing-component .landing-container .subscribe-container #subscribe .subscribe-form .subscribe-feedback.success {
  color: var(--pf-blue);
}
.landing-component .landing-container .subscribe-container #subscribe .subscribe-form .subscribe-feedback.existing-contact {
  color: var(--bs-green);
}
.landing-component .landing-container .subscribe-container #subscribe .subscribe-form .subscribe-feedback.invalid {
  color: #FF5555;
}
.landing-component .landing-container .subscribe-container #subscribe .subscribe-form #email-input-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.landing-component .landing-container .subscribe-container #subscribe .subscribe-form #email-input-box #email-input-inner {
  width: 100%;
  padding: 11px 23px;
  border-radius: 8px;
  border: 1px solid #6E6E6E;
  background: #FFF;
}
.landing-component .landing-container .subscribe-container #subscribe .subscribe-form #email-input-box #email-input-button {
  position: absolute;
  margin-right: 23px;
  padding: 6px 48px;
  background: rgba(60, 108, 233, 0.4);
}
.landing-component .landing-container .subscribe-container #subscribe .subscribe-form #email-input-box #email-input-button:hover {
  background: #3c6ce9;
}
@media screen and (max-width: 550px) {
  .landing-component .landing-container .subscribe-container #subscribe .subscribe-form #email-input-box #email-input-button {
    padding: 6px 6px;
  }
}
.landing-component .landing-container .red-gradient {
  position: absolute;
  top: 273px;
  right: -400px;
  background: linear-gradient(191deg, #FF3A3A 13.77%, rgba(255, 223, 223, 0) 80.68%);
  filter: blur(125px);
  width: 833.475px;
  height: 894.097px;
  transform: rotate(40.575deg);
  flex-shrink: 0;
}
.landing-component .landing-container .blue-gradient {
  position: absolute;
  top: 0;
  margin-left: 509px;
  background: linear-gradient(141deg, #3C6CE9 22.83%, rgba(214, 225, 255, 0) 48.81%);
  filter: blur(125px);
  width: 799.258px;
  height: 604.278px;
  transform: rotate(49.532deg);
  flex-shrink: 0;
}
.landing-component .landing-container .yellow-gradient {
  position: absolute;
  left: -350px;
  top: 319px;
  background: linear-gradient(6deg, #FFDA7B 27.7%, rgba(255, 240, 203, 0) 110.86%);
  filter: blur(125px);
  width: 652.391px;
  height: 729.118px;
  transform: rotate(96.09deg);
  flex-shrink: 0;
}
.landing-component .mobile-gradient-bg {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  width: 100%;
}
.landing-component .down-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 50;
  margin: -100px 0 -50px;
}
.landing-component .down-button svg {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .landing-component .down-button {
    display: none;
  }
}
